var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('v-card-title',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[(true)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","plain":"","icon":"","to":{
                name: 'facturacion_servicios.detalle',
                params: { id: _vm.id },
              }}},on),[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)]}}],null,false,2297752297)},[_c('span',{domProps:{"textContent":_vm._s('Átras')}})]):_vm._e(),_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(_vm.$route.meta.title)}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"info","loading":_vm.faqs_ldg,"disabled":_vm.faqs.length == 0},on:{"click":function($event){$event.stopPropagation();_vm.faqs_dlg = true}}},on),[_c('v-icon',[_vm._v(" mdi-help ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Ayuda')}})])],1)],1)],1),(_vm.rs_service)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"elevation":"1","dense":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-caption",domProps:{"textContent":_vm._s(("SERVICIO | " + (_vm.rs_service.folio_full)))}})]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ViewData',{attrs:{"label":"F. creación","value":_vm.rs_service.created_at}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ViewData',{attrs:{"label":"Asegurado","value":_vm.rs_service.rs.insured_full_name}})],1),(_vm.rs_service.doctor)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ViewData',{attrs:{"label":"Médico","value":'IDM ' +
                    _vm.rs_service.doctor.id +
                    ' | ' +
                    _vm.rs_service.doctor.full_name}})],1):_vm._e(),(_vm.rs_service.specialty_type)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ViewData',{attrs:{"label":"Especialidad","value":_vm.rs_service.specialty_type.specialty_type}})],1):_vm._e(),(
                  _vm.rs_service.consulting_room &&
                  _vm.rs_service.consulting_room.provider
                )?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ViewData',{attrs:{"label":"Consult. | Hosp.","value":_vm.rs_service.consulting_room.provider.trade_name}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ViewData',{attrs:{"label":"Monto","value":_vm.numberFormat(_vm.rs_service.benefit_amount)}})],1),(_vm.rs_service.rs.rs_documents)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.rs_service.rs.rs_documents),function(rs_document,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-caption font-weight-bold",domProps:{"textContent":_vm._s(("Documento (" + (i + 1) + ")"))}}),_c('br'),_c('span',{staticClass:"text-description",domProps:{"textContent":_vm._s(rs_document.description)}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","href":_vm.url_documents +
                                '/rs_documents/' +
                                rs_document.url,"target":"_blank"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Ver')}})])],1)],1)],1)}),1)],1):_vm._e()],1)],1)],1)],1),(_vm.rs_service.rs_service_insured_bill)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"elevation":"1","dense":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-caption",domProps:{"textContent":_vm._s(
                  ("COBRO | " + (_vm.rs_service.rs_service_insured_bill.folio) + " | " + (!_vm.rs_service.rs_service_insured_bill.stamp_id
                      ? 'SIN'
                      : 'CON') + " FACTURA")
                )}})]),_c('v-spacer'),_c('v-toolbar-items',[(_vm.rs_service.rs_service_insured_bill.stamp_id)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"color":"cyan darken-3","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.downloadFile(
                        _vm.rs_service.rs_service_insured_bill.stamp_id,
                        'pdf'
                      )}}},on),[_c('v-icon',[_vm._v(" mdi-file-download ")])],1)]}}],null,false,1277361022)},[_c('span',{domProps:{"textContent":_vm._s('Descargar PDF')}})]):_vm._e(),(_vm.rs_service.rs_service_insured_bill.stamp_id)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"color":"teal darken-2","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.downloadFile(
                        _vm.rs_service.rs_service_insured_bill.stamp_id,
                        'xml'
                      )}}},on),[_c('v-icon',[_vm._v(" mdi-xml ")])],1)]}}],null,false,1617634523)},[_c('span',{domProps:{"textContent":_vm._s('Descargar XML')}})]):_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"color":"teal darken-2","icon":"","href":_vm.url_pdf +
                      '/comprobante/pago/' +
                      _vm.rs_service.rs_service_insured_bill.id,"target":"_blank"}},on),[_c('v-icon',[_vm._v(" mdi-file-certificate ")])],1)]}}],null,false,879644210)},[_c('span',{domProps:{"textContent":_vm._s('Descargar Comprobante')}})])],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ViewData',{attrs:{"label":"Paciente | C. electrónico","value":_vm.rs_service.rs.email}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ViewData',{attrs:{"label":"Monto*","value":_vm.numberFormat(
                      _vm.rs_service.rs_service_insured_bill.pay_amount
                    )}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ViewData',{attrs:{"label":"Total cobrado","value":_vm.numberFormat(
                      _vm.rs_service.rs_service_insured_bill.insurance_pay_amount
                    )}})],1)],1),(_vm.rs_service.rs_service_insured_bill.stamp_id)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ViewData',{attrs:{"label":"Razón social","value":_vm.rs_service.rs_service_insured_bill.legal_name}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ViewData',{attrs:{"label":"RFC","value":_vm.rs_service.rs_service_insured_bill.code_tax}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ViewData',{attrs:{"label":"Código postal","value":_vm.rs_service.rs_service_insured_bill.legal_zip}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ViewData',{attrs:{"label":"Régimen","value":_vm.rs_service.rs_service_insured_bill.fiscal_regime
                      .fiscal_regime}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ViewData',{attrs:{"label":"Uso CFDI","value":_vm.rs_service.rs_service_insured_bill.fiscal_use.fiscal_use}})],1)],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ViewData',{attrs:{"label":"Forma de pago","value":_vm.rs_service.rs_service_insured_bill.fiscal_method_payment
                      .fiscal_method_payment}})],1),(
                  _vm.rs_service.rs_service_insured_bill
                    .fiscal_method_payment_id == 4 ||
                  _vm.rs_service.rs_service_insured_bill
                    .fiscal_method_payment_id == 18
                )?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ViewData',{attrs:{"label":"Núm. de aprobación","value":_vm.rs_service.rs_service_insured_bill.approval_number}})],1):_vm._e(),(
                  _vm.rs_service.rs_service_insured_bill
                    .fiscal_method_payment_id == 4 ||
                  _vm.rs_service.rs_service_insured_bill
                    .fiscal_method_payment_id == 18
                )?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ViewData',{attrs:{"label":"Tarjeta (4 últ. dig.)","value":_vm.rs_service.rs_service_insured_bill.card}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('ViewData',{attrs:{"label":"Observación","value":_vm.rs_service.rs_service_insured_bill.observation}})],1)],1)],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"tile":"","loading":_vm.step_loading,"disabled":_vm.step_loading}},[_c('v-toolbar',{attrs:{"elevation":"1","dense":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-caption",domProps:{"textContent":_vm._s('DOCUMENTACIÓN')}})])],1),_c('v-spacer'),_c('v-card-text',{staticClass:"px-0 pb-0 pt-1"},[_c('v-stepper',{attrs:{"vertical":"","flat":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-step',{attrs:{"complete":_vm.step > 1,"step":"1"}},[_vm._v(" Archivos ")]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-form',{ref:"data_form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',[_c('b',[_vm._v("Factura (pdf)")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.preventDefault();return _vm.documentBill0Edit()}}},on),[_c('v-icon',{attrs:{"x-":"","small":""}},[_vm._v(" mdi-pencil"+_vm._s(_vm.data.document_bill_0_edit ? "-off" : "")+" ")])],1)]}}],null,false,3510802727)},[_vm._v(" Editar ")])],1),(_vm.data.document_bill_0_edit)?_c('div',[_c('v-file-input',{attrs:{"accept":".pdf","rules":_vm.rules.required,"show-size":"","dense":""},model:{value:(_vm.data.document_bill_0),callback:function ($$v) {_vm.$set(_vm.data, "document_bill_0", $$v)},expression:"data.document_bill_0"}})],1):_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"info","href":_vm.url_documents +
                                '/rs_service_bill_documents/' +
                                _vm.data.documents[0].url_bill,"target":"_blank"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,false,1548034149)},[_vm._v(" Ver ")])],1)]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',[_c('b',[_vm._v("Factura (xml)")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.preventDefault();return _vm.documentBillXml0Edit()}}},on),[_c('v-icon',{attrs:{"x-":"","small":""}},[_vm._v(" mdi-pencil"+_vm._s(_vm.data.document_bill_xml_0_edit ? "-off" : "")+" ")])],1)]}}],null,false,350689752)},[_vm._v(" Editar ")])],1),(_vm.data.document_bill_xml_0_edit)?_c('div',[_c('v-file-input',{attrs:{"accept":".xml","rules":_vm.rules.required,"show-size":"","dense":""},model:{value:(_vm.data.document_bill_xml_0),callback:function ($$v) {_vm.$set(_vm.data, "document_bill_xml_0", $$v)},expression:"data.document_bill_xml_0"}})],1):_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"info","href":_vm.url_documents +
                                '/rs_service_bill_documents/' +
                                _vm.data.documents[0].url_bill_xml,"target":"_blank"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,false,1977564867)},[_vm._v(" Ver ")])],1)]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',[_c('b',[_vm._v("Monto total con IVA")])]),_c('div',[_c('v-text-field',{attrs:{"type":"number","prepend-icon":"mdi-currency-usd","rules":_vm.rules.required,"dense":""},model:{value:(_vm.data.documents[0].amount_typed),callback:function ($$v) {_vm.$set(_vm.data.documents[0], "amount_typed", $$v)},expression:"data.documents[0].amount_typed"}})],1)]),(!_vm.rs_service.rs_service_insured_bill)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',[_c('b',[_vm._v("Carta autorización (pdf)")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.preventDefault();return _vm.documentLetterAuth0Edit()}}},on),[_c('v-icon',{attrs:{"x-":"","small":""}},[_vm._v(" mdi-pencil"+_vm._s(_vm.data.document_letter_auth_0_edit ? "-off" : "")+" ")])],1)]}}],null,false,3043763864)},[_vm._v(" Editar ")])],1),(_vm.data.document_letter_auth_0_edit)?_c('div',[_c('v-file-input',{attrs:{"accept":".pdf","rules":_vm.rules.required,"show-size":"","dense":""},model:{value:(_vm.data.document_letter_auth_0),callback:function ($$v) {_vm.$set(_vm.data, "document_letter_auth_0", $$v)},expression:"data.document_letter_auth_0"}})],1):_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"info","href":_vm.url_documents +
                                '/rs_service_bill_documents/' +
                                _vm.data.documents[0].url_letter_auth,"target":"_blank"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,false,248582471)},[_vm._v(" Ver ")])],1)]):_vm._e()],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}}),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-btn',{attrs:{"block":"","color":"primary","small":""},on:{"click":function($event){$event.preventDefault();return _vm.docVerify.apply(null, arguments)}}},[_vm._v(" Continuar "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1),_c('v-stepper-step',{attrs:{"complete":_vm.step > 2,"step":"2"}},[_vm._v(" Verificación ")]),_c('v-stepper-content',{attrs:{"step":"2"}},[(_vm.step == 2)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.data.bills_repeat)?_c('v-alert',{attrs:{"type":"error","dense":""}},[_c('div',{staticClass:"text-center",domProps:{"textContent":_vm._s(
                          'Una o varias facturas XML cargadas estan repetidas'
                        )}})]):_vm._e(),(
                        _vm.data.valid_rs_service_insured_bill_pay_amount == false
                      )?_c('v-alert',{attrs:{"type":"error","dense":""}},[_c('div',{staticClass:"text-center",domProps:{"textContent":_vm._s(
                          'El monto subtotal de la factura no concuerda con el monto del cobro'
                        )}})]):_vm._e()],1),_vm._l((_vm.data.documents),function(document,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"grey darken-1 white--text",attrs:{"cols":"12"}},[_c('h3',{staticClass:"pl-1",domProps:{"textContent":_vm._s(("Factura (" + (i + 1) + ")"))}})]),_c('v-col',{attrs:{"cols":"12"}},[(document.xml && document.bill_exist)?_c('v-alert',{attrs:{"type":"error","dense":""}},[_c('div',{staticClass:"text-center",domProps:{"textContent":_vm._s(
                              ("El folio de esta factura ya ha sido registrado para el servicio " + (document.bill_exist_rs_folio))
                            )}})]):_vm._e(),(!document.xml)?_c('v-alert',{attrs:{"type":"error","dense":""}},[_c('div',{staticClass:"text-center",domProps:{"textContent":_vm._s(
                              'La factura XML no cuenta con un formato valido de lectura SAT'
                            )}})]):_vm._e(),(document.xml && !document.amount_typed_valid)?_c('v-alert',{attrs:{"type":"error","dense":""}},[_c('div',{staticClass:"text-center",domProps:{"textContent":_vm._s(
                              'El monto total con IVA no es igual al Total de la factura'
                            )}})]):_vm._e(),(
                            document.xml && !document.valid_receiver_code_tax
                          )?_c('v-alert',{attrs:{"type":"error","dense":""}},[_c('div',{staticClass:"text-center",domProps:{"textContent":_vm._s(
                              'El RFC del receptor no concuerda con el de Sinergia Médica'
                            )}})]):_vm._e(),(document.xml && !document.valid_receiver_name)?_c('v-alert',{attrs:{"type":"warning","dense":""}},[_c('div',{staticClass:"text-center",domProps:{"textContent":_vm._s(
                              'La razón social del receptor no concuerda con el de Sinergia Médica'
                            )}})]):_vm._e(),(
                            document.xml &&
                            !document.valid_transmitter_code_tax
                          )?_c('v-alert',{attrs:{"type":"error","dense":""}},[_c('div',{staticClass:"text-center",domProps:{"textContent":_vm._s(
                              'El RFC del emisor no concuerda con los datos registrados en nuestra base de datos'
                            )}})]):_vm._e(),(document.xml && !document.valid_bill)?_c('v-alert',{attrs:{"type":"error","dense":""}},[_c('div',{staticClass:"text-center",domProps:{"textContent":_vm._s(
                              'Los cálculos de ISR y/o IVA retenidos no son correctos'
                            )}})]):_vm._e(),(document.xml && !document.valid_regimen)?_c('v-alert',{attrs:{"type":"error","dense":""}},[_c('div',{staticClass:"text-center",domProps:{"textContent":_vm._s('El régimen legal no es válido')}})]):_vm._e()],1),(document.xml)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('ViewData',{class:document.valid_receiver_name
                                  ? ''
                                  : 'orange--text text--darken-3',attrs:{"label":"Receptor","value":document.xml.Receptor.attr.Nombre}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('ViewData',{class:document.valid_receiver_code_tax
                                  ? ''
                                  : 'red--text text--darken-3',attrs:{"label":"RFC","value":document.xml.Receptor.attr.Rfc}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('ViewData',{attrs:{"label":"Fecha y hora de expedición","value":document.xml_stamped_date}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('ViewData',{class:!document.status_account_valid &&
                                !document.amount_typed_valid
                                  ? 'red--text text--darken-3'
                                  : '',attrs:{"label":"Monto total con IVA","value":_vm.numberFormat(document.amount_typed)}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('ViewData',{class:document.valid_transmitter_name
                                  ? ''
                                  : 'red--text text--darken-3',attrs:{"label":"Emisor","value":document.xml.Emisor.attr.Nombre}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('ViewData',{class:document.valid_transmitter_code_tax
                                  ? ''
                                  : 'red--text text--darken-3',attrs:{"label":"RFC","value":document.xml.Emisor.attr.Rfc}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('ViewData',{attrs:{"label":"Folio","value":document.xml_folio}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('ViewData',{class:document.valid_regimen
                                  ? ''
                                  : 'red--text text--darken-3',attrs:{"label":"Régimen fiscal","value":document.xml.Emisor.attr.RegimenFiscal}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-caption font-weight-bold",domProps:{"textContent":_vm._s('Conceptos')}}),_c('small',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{domProps:{"textContent":_vm._s('#')}}),_c('th',{domProps:{"textContent":_vm._s('Unidad')}}),_c('th',{domProps:{"textContent":_vm._s('Cantidad')}}),_c('th',{domProps:{"textContent":_vm._s('Clave')}}),_c('th',{domProps:{"textContent":_vm._s('Descripción')}}),_c('th',{domProps:{"textContent":_vm._s('Precio')}}),_c('th',{domProps:{"textContent":_vm._s('Importe')}})])]),_c('tbody',[_vm._l((document.xml
                                        .Conceptos.Concepto),function(concept,j){return _c('tr',{key:j},[_c('td',{staticClass:"text-caption font-weight-bold",domProps:{"textContent":_vm._s(j + 1)}}),_c('td',{staticClass:"text-caption",domProps:{"textContent":_vm._s(concept.attr.ClaveUnidad)}}),_c('td',{staticClass:"text-caption",domProps:{"textContent":_vm._s(concept.attr.Cantidad)}}),_c('td',{staticClass:"text-caption",domProps:{"textContent":_vm._s(concept.attr.ClaveProdServ)}}),_c('td',{staticClass:"text-caption",domProps:{"textContent":_vm._s(concept.attr.Descripcion)}}),_c('td',{staticClass:"text-caption",domProps:{"textContent":_vm._s(
                                          _vm.numberFormat(
                                            concept.attr.ValorUnitario
                                          )
                                        )}}),_c('td',{staticClass:"text-caption",domProps:{"textContent":_vm._s(
                                          _vm.numberFormat(concept.attr.Importe)
                                        )}})])}),_c('tr',[_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td',{staticClass:"text-caption font-weight-bold",domProps:{"textContent":_vm._s('Subtotal')}}),_c('td',{staticClass:"text-caption",domProps:{"textContent":_vm._s(
                                          _vm.numberFormat(
                                            document.xml.attr.SubTotal
                                          )
                                        )}})]),_c('tr',[_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td',{staticClass:"text-caption font-weight-bold",domProps:{"textContent":_vm._s('Descuento')}}),_c('td',{staticClass:"text-caption",domProps:{"textContent":_vm._s(
                                          _vm.numberFormat(
                                            document.xml.attr.Descuento
                                              ? document.xml.attr.Descuento
                                              : 0
                                          )
                                        )}})]),(document.valid_iva != null)?_c('tr',[_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td',{class:("text-caption font-weight-bold" + (document.valid_iva
                                            ? ''
                                            : ' red--text text--darken-3')),domProps:{"textContent":_vm._s('Impuestos Trasladados')}}),_c('td',{staticClass:"text-caption",domProps:{"textContent":_vm._s(
                                          _vm.numberFormat(document.importe_iva)
                                        )}})]):_vm._e(),(document.valid_isr != null)?_c('tr',[_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td',{class:("text-caption font-weight-bold" + (document.valid_isr
                                            ? ''
                                            : ' red--text text--darken-3')),domProps:{"textContent":_vm._s('Impuestos Retenidos')}}),_c('td',{staticClass:"text-caption",domProps:{"textContent":_vm._s(
                                          _vm.numberFormat(
                                            document.importe_isr
                                              ? document.importe_isr
                                              : 0
                                          )
                                        )}})]):_vm._e(),_c('tr',[_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td',{staticClass:"text-caption font-weight-bold",domProps:{"textContent":_vm._s('IVA')}}),_c('td',{staticClass:"text-caption",domProps:{"textContent":_vm._s(
                                          document.xml.Impuestos &&
                                          document.xml.Impuestos.attr
                                            .TotalImpuestosTrasladados
                                            ? _vm.numberFormat(
                                                document.xml.Impuestos.attr
                                                  .TotalImpuestosTrasladados
                                              )
                                            : _vm.numberFormat(0)
                                        )}})]),_c('tr',[_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td',{staticClass:"text-caption font-weight-bold",domProps:{"textContent":_vm._s('Total')}}),_c('td',{staticClass:"text-caption font-weight-bold",domProps:{"textContent":_vm._s(
                                          _vm.numberFormat(document.xml_amount)
                                        )}})])],2)]},proxy:true}],null,true)})],1)])],1)],1):_vm._e()],1)],1)})],2):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":""},on:{"click":function($event){$event.preventDefault();return _vm.docFormData.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-chevron-left ")]),_vm._v(" Atrás ")],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","color":_vm.data.to_step_3 ? 'primary' : ''},on:{"click":function($event){$event.preventDefault();return _vm.docBeforeSubmit.apply(null, arguments)}}},[_vm._v(" Continuar "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1),_c('v-stepper-step',{attrs:{"complete":_vm.step > 3,"step":"3"}},[_vm._v(" Envío ")]),_c('v-stepper-content',{attrs:{"step":"3"}},[(_vm.step == 3)?_c('v-row',[(!_vm.data.to_stamp)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"warning","dense":""}},[_c('div',{staticClass:"text-center",domProps:{"textContent":_vm._s(
                          'La documentación a cargar pasara a un proceso de revisión'
                        )}}),(!_vm.data.valid_assured_amount)?_c('div',{staticClass:"text-center",domProps:{"textContent":_vm._s(
                          '*El monto de la factura supera el monto asegurado'
                        )}}):_vm._e()])],1):_vm._e(),(!_vm.rs_service.rs_service_insured_bill)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-caption font-weight-bold mr-1",domProps:{"textContent":_vm._s('Identificación')}}),_c('v-icon',{attrs:{"right":"","small":"","color":(_vm.store_mode && _vm.data.document_credential_0) ||
                        (!_vm.store_mode && _vm.data.url_credential)
                          ? 'success'
                          : 'warning'}},[_vm._v(" "+_vm._s((_vm.store_mode && _vm.data.document_credential_0) || (!_vm.store_mode && _vm.data.url_credential) ? "mdi-check" : "mdi-alert")+" ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('span',{staticClass:"text-caption font-weight-bold mr-1",domProps:{"textContent":_vm._s('Total factura')}}),_c('span',{domProps:{"textContent":_vm._s(_vm.numberFormat(_vm.data.xmls_amount))}})]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('span',{staticClass:"text-caption font-weight-bold mr-1",domProps:{"textContent":_vm._s('Total IVA')}}),_c('span',{domProps:{"textContent":_vm._s(_vm.numberFormat(_vm.data.xmls_tax_amount))}})]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}}),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('span',{staticClass:"text-caption font-weight-bold mr-1",domProps:{"textContent":_vm._s('Total a pagar')}}),_c('span',{domProps:{"textContent":_vm._s(_vm.numberFormat(_vm.data.pay_amount))}})]),_vm._l((_vm.data.documents),function(document,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"grey darken-3 white--text",attrs:{"cols":"12"}},[_c('h4',{staticClass:"pl-1",domProps:{"textContent":_vm._s(
                            ("Factura (" + (i + 1) + ") | " + (document.xml_folio))
                          )}})]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('span',{staticClass:"text-caption font-weight-bold mr-1",domProps:{"textContent":_vm._s('Factura PDF')}}),_c('v-icon',{attrs:{"right":"","small":"","color":(_vm.store_mode && _vm.data['document_bill_' + i]) ||
                            (!_vm.store_mode && document.url_bill)
                              ? 'success'
                              : 'warning'}},[_vm._v(" "+_vm._s((_vm.store_mode && _vm.data["document_bill_" + i]) || (!_vm.store_mode && document.url_bill) ? "mdi-check" : "mdi-alert")+" ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('span',{staticClass:"text-caption font-weight-bold mr-1",domProps:{"textContent":_vm._s('Factura XML')}}),_c('v-icon',{attrs:{"right":"","small":"","color":document.xml_valid ? 'success' : 'warning'}},[_vm._v(" "+_vm._s(document.xml_valid ? "mdi-check" : "mdi-alert")+" ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('span',{staticClass:"text-caption font-weight-bold mr-1",domProps:{"textContent":_vm._s('RFC y razon social')}}),_c('v-icon',{attrs:{"right":"","small":"","color":document.valid_receiver_code_tax &&
                            document.valid_receiver_name
                              ? 'success'
                              : 'warning'}},[_vm._v(" "+_vm._s(document.valid_receiver_code_tax && document.valid_receiver_name ? "mdi-check" : "mdi-alert")+" ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('span',{staticClass:"text-caption font-weight-bold mr-1",domProps:{"textContent":_vm._s('Monto XML')}}),_c('span',{domProps:{"textContent":_vm._s(_vm.numberFormat(document.xml_amount))}})]),(!_vm.rs_service.rs_service_insured_bill)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('span',{staticClass:"text-caption font-weight-bold mr-1",domProps:{"textContent":_vm._s('Carta autorización')}}),_c('v-icon',{attrs:{"right":"","small":"","color":(_vm.store_mode &&
                              _vm.data['document_letter_auth_' + i]) ||
                            (!_vm.store_mode && document.url_letter_auth)
                              ? 'success'
                              : 'warning'}},[_vm._v(" "+_vm._s((_vm.store_mode && _vm.data["document_letter_auth_" + i]) || (!_vm.store_mode && document.url_letter_auth) ? "mdi-check" : "mdi-alert")+" ")])],1):_vm._e()],1)],1)})],2):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":""},on:{"click":function($event){$event.preventDefault();_vm.step = 2}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-chevron-left ")]),_vm._v(" Atrás ")],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","color":"success"},on:{"click":function($event){$event.preventDefault();return _vm.docSubmit.apply(null, arguments)}}},[_vm._v(" Cargar "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-upload ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1):_vm._e(),_c('FaqDlg',{attrs:{"faqs":_vm.faqs},model:{value:(_vm.faqs_dlg),callback:function ($$v) {_vm.faqs_dlg=$$v},expression:"faqs_dlg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }