<template>
  <v-card class="mx-auto" :disabled="ldg">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: page_route ? page_route + '.rha_catastrophics' : '',
                  params: { id: id },
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              $route.meta.title +
              (tbl.length > 0 ? ' (' + tbl.length + ')' : '')
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip
            v-if="log.permissions.rha_benefit_details_amounts.update"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="pink"
                dark
                @click.stop="dataDlg"
              >
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span v-text="'Editar'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <RhaData :rha_id="id" />
        </v-col>
        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'DETALLE'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="tbl_srch"
                    append-icon="mdi-magnify"
                    label="Buscar en tabla..."
                    single-line
                    hide-details
                    dense
                  />
                </v-col>
                <v-col cols="12">
                  <v-data-table
                    :headers="tbl_hdrs"
                    :search="tbl_srch"
                    :items="tbl"
                    :loading="ldg"
                    :items-per-page="15"
                    dense
                  >
                    <template v-slot:item.key="{ item }">
                      <b v-text="item.key + 1" />
                    </template>
                    <template v-slot:item.amount="{ item }">
                      {{ numberFormat(item.amount) }}
                    </template>
                    <template v-slot:item.total_amount="{ item }">
                      {{ numberFormat(item.total_amount) }}
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="12" class="text-right">
                  <b>Total: </b> {{ numberFormat(tbl_total_amount) }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="data_dlg" scrollable persistent max-width="1800">
      <v-card tile :disabled="data_dlg_ldg" :loading="data_dlg_ldg">
        <v-toolbar dark dense>
          <v-toolbar-title> EDITAR </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="data_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="data">
          <v-row dense>
            <v-col cols="12" class="pt-3" />
            <v-col cols="12" xs="12" md="10">
              <v-autocomplete
                label="Prestación"
                v-model="benefit_detail_id"
                :items="benefit_details"
                item-value="id"
                :item-text="
                  (item) =>
                    item.code +
                    ' | ' +
                    item.description +
                    ' | ' +
                    numberFormat(item.amount)
                "
                :loading="benefit_details_ldg"
                dense
              />
            </v-col>
            <v-col cols="12" xs="12" md="2">
              <v-btn
                small
                block
                color="info"
                @click.prevent="addItem"
                :disabled="!benefit_detail_id"
              >
                Añadir
                <v-icon right> mdi-plus </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                x-small
                color="pink"
                dark
                @click="searchBenefitsDlg(false)"
              >
                Buscar
                <v-icon small right> mdi-magnify-expand </v-icon>
              </v-btn>
              <v-btn
                x-small
                color="info"
                @click="statusAccountBenefitsDlg()"
                class="ml-1"
                :loading="status_account_ldg"
                :disabled="
                  !status_account || status_account.benefits.length == 0
                "
              >
                Edo. Cuenta
                <v-icon small right> mdi-file-search </v-icon>
              </v-btn>
              <v-tooltip
                v-if="
                  status_account &&
                  status_account.rha_amount_document_id &&
                  !status_account.rha_status_account_id
                "
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    x-small
                    class="ml-1"
                    color="info"
                    @click.prevent="statusAccountFormatDlg"
                  >
                    <v-icon small> mdi-update </v-icon>
                  </v-btn>
                </template>
                Formato de lectura
              </v-tooltip>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <v-form v-on:submit.prevent ref="data_form" lazy-validation>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th v-text="'#'" />
                            <th v-text="'Tipo'" />
                            <th v-text="'Código'" />
                            <th v-text="'Descripción'" />
                            <th v-text="'Sub-Tipo'" />
                            <th v-text="'Definición'" />
                            <th v-text="'Cantidad'" />
                            <th v-text="'Monto'" />
                            <th v-text="'Total'" />
                            <th v-text="'Observación'" />
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, i) in data"
                            :key="i"
                            v-if="item.active"
                          >
                            <td v-text="i + 1" />
                            <td>
                              <div
                                v-if="item.benefit_detail_id"
                                v-text="
                                  item.benefit_detail.benefit.benefit_type
                                    .benefit_type
                                "
                              />
                              <div v-else>
                                <v-autocomplete
                                  v-model="
                                    item.benefit_detail.benefit.benefit_type_id
                                  "
                                  item-value="id"
                                  :items="benefit_types"
                                  :loading="benefit_types_ldg"
                                  :item-text="(v) => v.benefit_type"
                                  :rules="rules.required"
                                  dense
                                />
                              </div>
                            </td>
                            <td>
                              <div
                                v-if="item.benefit_detail_id"
                                v-text="item.benefit_detail.benefit.code"
                              />
                              <div v-else>
                                <v-text-field
                                  v-model="item.benefit_detail.benefit.code"
                                  dense
                                  maxlength="30"
                                  :rules="rules.required"
                                />
                              </div>
                            </td>
                            <td>
                              <div
                                v-if="item.benefit_detail_id"
                                v-text="item.benefit_detail.description"
                              />
                              <div v-else>
                                <v-text-field
                                  v-model="item.benefit_detail.description"
                                  dense
                                  maxlength="250"
                                  :rules="rules.required"
                                />
                              </div>
                            </td>
                            <td>
                              <div
                                v-if="item.benefit_detail_id"
                                v-text="
                                  item.benefit_detail.benefit_type
                                    ? item.benefit_detail.benefit_type
                                        .benefit_type
                                    : null
                                "
                              />
                              <div v-else>
                                <v-autocomplete
                                  v-model="item.benefit_detail.benefit_type_id"
                                  item-value="id"
                                  :items="benefit_types"
                                  :loading="benefit_types_ldg"
                                  :item-text="(v) => v.benefit_type"
                                  dense
                                />
                              </div>
                            </td>
                            <td>
                              <div
                                v-if="item.benefit_detail_id"
                                v-text="item.benefit_detail.definition"
                              />
                              <div v-else>
                                <v-text-field
                                  v-model="item.benefit_detail.definition"
                                  dense
                                />
                              </div>
                            </td>
                            <td>
                              <v-text-field
                                v-model="item.quantity"
                                type="number"
                                dense
                                min="1"
                                :rules="rules.required"
                                @input="itemTotalAmount(item)"
                              />
                            </td>
                            <td>
                              <v-text-field
                                v-model="item.amount"
                                type="number"
                                dense
                                min="1"
                                :rules="rules.required"
                                @input="itemTotalAmount(item)"
                              />
                            </td>
                            <td v-text="numberFormat(item.total_amount)" />
                            <td>
                              <v-text-field v-model="item.observation" dense />
                            </td>
                            <td>
                              <v-btn
                                v-if="
                                  log.permissions.rha_benefit_details_amounts
                                    .delete
                                "
                                x-small
                                icon
                                color="error"
                                @click="removeItem(i)"
                              >
                                <v-icon small> mdi-close </v-icon>
                              </v-btn>

                              <v-btn
                                x-small
                                icon
                                dark
                                color="pink"
                                @click="
                                  searchBenefitsDlg(
                                    true,
                                    item.benefit_detail.description
                                  )
                                "
                              >
                                <v-icon small> mdi-magnify-expand </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                          <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td>
                              <b>{{ total_quantity }}</b>
                            </td>
                            <td />
                            <td>
                              <b>{{ numberFormat(total_amount) }}</b>
                            </td>
                            <td />
                            <td />
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-form>
                </v-col>

                <v-col cols="6">
                  <v-btn
                    v-if="log.permissions.benefits.create"
                    x-small
                    color="warning"
                    @click="createItem"
                  >
                    Crear

                    <v-icon small right> mdi-database-plus </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn small color="success" @click.prevent="saveData">
                    Guardar
                    <v-icon right> mdi-send </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="search_benefits_dlg"
      scrollable
      persistent
      max-width="1800"
    >
      <v-card
        tile
        :disabled="search_benefits_dlg_ldg"
        :loading="search_benefits_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> BUSCAR </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="search_benefits_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="search_benefit">
          <v-row dense>
            <v-col cols="12" class="pt-3" />
            <v-col cols="12" xs="12" md="3">
              <v-text-field
                label="Código"
                v-model="search_benefit.code"
                maxlength="30"
                dense
                type="text"
                :disabled="search_benefits.length > 0"
              />
            </v-col>
            <v-col cols="12" xs="12" md="3">
              <v-text-field
                label="Descripción"
                v-model="search_benefit.description"
                maxlength="250"
                dense
                type="text"
                :disabled="search_benefits.length > 0"
              />
            </v-col>
            <v-col v-if="search_benefits.length == 0" cols="12" xs="12" md="6">
              <v-btn
                block
                small
                color="success"
                @click.prevent="searchBenefits"
                :loading="search_benefits_dlg_ldg"
                :disabled="
                  search_benefit.code == '' && search_benefit.description == ''
                "
              >
                Buscar
                <v-icon right> mdi-magnify </v-icon>
              </v-btn>
            </v-col>
            <v-col v-else cols="12" xs="12" md="6">
              <v-btn block small color="" @click.prevent="search_benefits = []">
                Cambiar parametros
                <v-icon right> mdi-refresh </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="search_benefits_srch"
                append-icon="mdi-magnify"
                label="Buscar en tabla..."
                single-line
                hide-details
                dense
                :disabled="search_benefits.length == 0"
              />
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="search_benefits_hdrs"
                :search="search_benefits_srch"
                :items="search_benefits"
                :items-per-page="15"
                dense
              >
                <template v-slot:item.key="{ item }">
                  <b v-text="item.key + 1" />
                </template>
                <template v-slot:item.amount="{ item }">
                  {{ numberFormat(item.amount) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="status_account_dlg"
      scrollable
      persistent
      max-width="1200"
    >
      <v-card
        tile
        :disabled="status_account_dlg_ldg"
        :loading="status_account_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> EDO. CUENTA </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="status_account_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" class="pt-3" />
            <v-col cols="12">
              <v-text-field
                v-model="status_account_benefits_srch"
                append-icon="mdi-magnify"
                label="Buscar en tabla..."
                single-line
                hide-details
                dense
              />
            </v-col>
            <v-col v-if="status_account" cols="12">
              <v-data-table
                :headers="status_account_benefits_hdrs"
                :search="status_account_benefits_srch"
                :items="status_account.benefits"
                :items-per-page="15"
                dense
                :item-class="statusAccountBenefitBG"
              >
                <template v-slot:item.index="{ item, index }">
                  <b v-text="index + 1" />
                </template>
                <template v-slot:item.amount="{ item }">
                  {{ numberFormat(item.amount) }}
                </template>
                <template v-slot:item.total_amount="{ item }">
                  {{ numberFormat(item.total_amount) }}
                </template>
                <template v-slot:item.action="{ item }">
                  <v-checkbox
                    v-if="!item.exception"
                    v-model="item.selected"
                    dense
                  />
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn small color="success" @click.prevent="exportBenefits">
                Exportar
                <v-icon right> mdi-file-export </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="status_account_format_dlg"
      scrollable
      persistent
      max-width="500"
    >
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title> EDO. CUENTA </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="status_account_format_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" class="pt-3" />
            <v-col cols="12">
              <v-select
                label="Formato"
                v-model="status_account_format_id"
                :items="status_account_formats"
                item-value="id"
                :item-text="(v) => v.status_account_format"
                :loading="status_account_formats_ldg"
              />
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn
                small
                color="success"
                @click.prevent="getStatusAccountBenefits(true)"
              >
                Analizar
                <v-icon right> mdi-send </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  numberFormat,
  rules,
  msgConfirm,
  msgAlert,
} from "@/control";
import { catalog } from "@/requests/catalogRequest";
import FaqDlg from "@/components/FaqDlg.vue";
import RhaData from "@/components/RhaData.vue";

export default {
  components: {
    FaqDlg,
    RhaData,
  },
  data() {
    return {
      numberFormat: numberFormat,
      rules: rules(),
      id: this.$route.params.id ? this.$route.params.id : 0,
      log: this.$store.getters.getLogin,
      page_route: null,
      ldg: false,
      tbl: [],
      tbl_hdrs: [
        {
          text: "#",
          value: "key",
          filterable: false,
          width: "50",
        },
        {
          text: "Tipo",
          value: "benefit_detail.benefit.benefit_type.benefit_type",
          filterable: true,
        },
        {
          text: "Código",
          value: "benefit_detail.benefit.code",
          filterable: true,
        },
        {
          text: "Descripción",
          value: "benefit_detail.description",
          filterable: true,
        },
        {
          text: "Sub-Tipo",
          value: "benefit_detail.benefit_type.benefit_type",
          filterable: true,
        },
        {
          text: "Definición",
          value: "benefit_detail.definition",
          filterable: true,
        },
        {
          text: "Cantidad",
          value: "quantity",
          filterable: true,
        },
        {
          text: "Monto",
          value: "amount",
          filterable: true,
        },
        {
          text: "Total",
          value: "total_amount",
          filterable: true,
        },
        {
          text: "Observación",
          value: "observation",
          filterable: true,
        },
      ],
      tbl_srch: "",
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
      data: [],
      data_dlg: false,
      data_dlg_ldg: false,
      benefit_details: [],
      benefit_details_ldg: true,
      benefit_detail_id: null,
      total_quantity: 0,
      total_amount: 0,
      benefit_types: [],
      benefit_types_ldg: true,
      tbl_total_amount: 0,
      search_benefits: [],
      search_benefits_dlg: false,
      search_benefits_dlg_ldg: false,
      search_benefit: null,
      search_benefits_hdrs: [
        {
          text: "#",
          value: "key",
          filterable: false,
          width: "50",
        },
        {
          text: "Proveedor",
          value: "provider.trade_name",
          filterable: true,
        },
        {
          text: "F. reg.",
          value: "created_at",
          filterable: true,
        },
        {
          text: "Tipo",
          value: "benefit_type.benefit_type",
          filterable: true,
        },
        {
          text: "Código",
          value: "code",
          filterable: true,
        },
        {
          text: "Descripción",
          value: "description",
          filterable: true,
        },
        {
          text: "Sub-Tipo",
          value: "benefit_detail_type.benefit_type",
          filterable: true,
        },
        {
          text: "Definición",
          value: "definition",
          filterable: true,
        },
        {
          text: "Monto",
          value: "amount",
          filterable: true,
        },
      ],
      search_benefits_srch: "",
      status_account: null,
      status_account_ldg: true,
      status_account_dlg: false,
      status_account_dlg_ldg: false,
      status_account_benefits_srch: "",
      status_account_benefits_hdrs: [
        {
          text: "#",
          value: "index",
          filterable: false,
          width: "50",
        },
        {
          text: "Tipo",
          value: "benefit_type.benefit_type",
          filterable: true,
        },
        {
          text: "Código",
          value: "code",
          filterable: true,
        },
        {
          text: "Descripción",
          value: "description",
          filterable: true,
        },
        {
          text: "Cantidad",
          value: "quantity",
          filterable: true,
        },
        {
          text: "Monto",
          value: "amount",
          filterable: true,
        },
        {
          text: "Total",
          value: "total_amount",
          filterable: true,
        },
        {
          text: "",
          value: "action",
          filterable: false,
          sortable: false,
          width: "60px",
        },
      ],
      rha: null,
      status_account_format_id: null,
      status_account_formats: [],
      status_account_formats_ldg: true,
      status_account_format_dlg: false,
    };
  },
  methods: {
    getTbl() {
      this.ldg = true;
      this.tbl = [];

      Axios.get(
        URL_API + "/rhas/" + this.id + "/benefit_details",
        headersToken(this.log.token)
      ).then((rsp) => {
        this.tbl = rsp.data.data;

        this.tbl_total_amount = 0;
        for (const itm of this.tbl) {
          if (itm.active) {
            this.tbl_total_amount += parseFloat(itm.total_amount);
          }
        }
        this.ldg = false;
      });
    },
    dataDlg() {
      this.data = JSON.parse(JSON.stringify(this.tbl));
      this.data_dlg_ldg = false;
      this.totals();
      this.data_dlg = true;
    },
    totals() {
      this.total_quantity = 0;
      this.total_amount = 0;
      for (const itm of this.data) {
        if (itm.active) {
          this.total_quantity += parseInt(itm.quantity);
          this.total_amount += parseFloat(itm.total_amount);
        }
      }
    },
    itemTotalAmount(item) {
      item.total_amount = parseInt(item.quantity) * parseFloat(item.amount);
      this.totals();
    },
    addItem(is_exported = false, quantity = null, amount = null) {
      const find = this.data.find(
        (itm) => itm.benefit_detail_id == this.benefit_detail_id
      );

      if (typeof find === "undefined") {
        this.data_dlg_ldg = true;

        let params = "";
        if (quantity != null && amount != null) {
          params = "?quantity=" + quantity + "&amount=" + amount;
        }

        Axios.get(
          URL_API +
            "/rhas/" +
            this.id +
            "/benefit_details/" +
            this.benefit_detail_id +
            "/get_object" +
            params,
          headersToken(this.log.token)
        ).then((res) => {
          this.data.push(res.data.data);
          this.benefit_detail_id = null;
          this.totals();
          this.data_dlg_ldg = false;
        });
      } else {
        if (!is_exported) {
          this.$swal.fire(
            msgAlert("warning", "La prestación ya ha sido seleccionada")
          );
        } else {
          this.data_dlg_ldg = true;
          this.data[find.key].quantity = quantity;
          this.data[find.key].amount = amount;
          this.benefit_detail_id = null;
          this.totals();
          this.data_dlg_ldg = false;
        }
      }
    },
    createItem() {
      this.data.push({
        id: null,
        active: true,
        rha_id: this.id,
        benefit_detail_id: null,
        benefit_detail: {
          benefit_id: null,
          benefit: {
            benefit_type_id: 1,
            code: "",
          },
          description: "",
          benefit_type_id: null,
          definition: "",
        },
        quantity: 1,
        amount: 1,
        total_amount: 1,
        observation: "",
      });

      this.totals();
    },
    removeItem(i) {
      this.$swal
        .fire(msgConfirm("¿Confirma eliminar el registro (" + (i + 1) + ")?"))
        .then((res) => {
          if (res.isConfirmed) {
            if (this.data[i].id == null) {
              this.data.splice(i, 1);
            } else {
              this.data[i].active = false;
            }

            this.totals();
          }
        });
    },
    saveData() {
      if (this.data.length > 0) {
        if (this.$refs.data_form.validate()) {
          this.$swal
            .fire(msgConfirm(`¿Confirma guardar la información?`))
            .then((res) => {
              if (res.isConfirmed) {
                this.data_dlg_ldg = true;

                Axios.post(
                  URL_API + "/rhas/benefit_details",
                  { items: this.data },
                  headersToken(this.log.token)
                ).then((res) => {
                  this.$swal.fire(
                    msgAlert(
                      res.data.success ? "success" : "error",
                      res.data.message
                    )
                  );

                  if (res.data.success) {
                    this.data_dlg = false;
                    this.providerList();
                    this.getTbl();
                  }

                  this.data_dlg_ldg = false;
                });
              }
            });
        } else {
          this.$swal.fire(
            msgAlert(
              "error",
              "Formulario invalido, revisa los detalles señalados"
            )
          );
        }
      } else {
        this.$swal.fire(
          msgAlert("error", "Se debe agregar al menos un registro")
        );
      }
    },
    providerList() {
      this.benefit_details_ldg = true;

      Axios.get(
        URL_API + "/rhas/" + this.id + "/benefit_details/provider_list",
        headersToken(this.log.token)
      ).then((res) => {
        this.benefit_details = res.data.data;
        this.benefit_details_ldg = false;
      });
    },
    searchBenefitsDlg(exec, description = "") {
      this.search_benefit = {
        code: "",
        description: description,
      };

      this.search_benefits = [];
      this.search_benefits_dlg_ldg = false;
      this.search_benefits_dlg = true;

      if (exec) {
        this.searchBenefits();
      }
    },
    searchBenefits() {
      this.search_benefits_dlg_ldg = true;
      this.search_benefits = [];
      this.search_benefits_srch = "";

      Axios.get(
        URL_API +
          "/providers/search_benefits" +
          "?code=" +
          this.search_benefit.code +
          "&description=" +
          this.search_benefit.description,
        headersToken(this.log.token)
      ).then((res) => {
        this.search_benefits = res.data.data;
        this.search_benefits_dlg_ldg = false;
      });
    },
    getStatusAccountBenefits(from_dlg = false) {
      this.status_account_ldg = true;
      this.status_account = null;
      this.status_account_format_dlg = false;

      Axios.get(
        URL_API +
          "/rhas/" +
          this.id +
          "/status_account/benefits" +
          (from_dlg
            ? "?status_account_format_id=" + this.status_account_format_id
            : ""),
        headersToken(this.log.token)
      ).then((rsp) => {
        if (rsp.data.data.benefits) {
          this.status_account = rsp.data.data;

          if (from_dlg) {
            this.statusAccountBenefitsDlg();
          }
        }

        this.status_account_ldg = false;
      });
    },
    statusAccountBenefitsDlg() {
      this.status_account_dlg_ldg = false;
      this.status_account_dlg = true;
    },
    statusAccountBenefitBG: function (item) {
      return item.exception ? "red lighten-5" : "";
    },
    exportBenefits() {
      this.status_account_dlg_ldg = true;

      for (const item of this.status_account.benefits) {
        if (item.selected) {
          this.benefit_detail_id = item.benefit.benefit_details[0].id;
          this.addItem(true, item.quantity, item.amount);
        }
      }

      this.status_account_dlg = false;
    },
    statusAccountFormatDlg() {
      this.status_account_format_id =
        this.rha.provider.status_account_format_id;

      this.status_account_format_dlg = true;
    },
  },

  mounted() {
    this.page_route = this.$route.name;
    this.page_route = this.page_route.split(".");
    this.page_route = this.page_route[0];

    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.log.token)
    ).then((res) => {
      this.faqs = res.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(URL_API + "/rha/" + this.id, headersToken(this.log.token)).then(
      (rsp) => {
        this.rha = rsp.data.data;
      }
    );

    Axios.get(URL_API + "/benefit/types", headersToken(this.log.token)).then(
      (rsp) => {
        this.benefit_types = rsp.data.data;
        this.benefit_types_ldg = false;
      }
    );

    catalog("status_account_formats", this.log.token).then((rsp) => {
      this.status_account_formats = rsp;
      this.status_account_formats_ldg = false;
    });

    this.providerList();
    this.getStatusAccountBenefits();
    this.getTbl();
  },
};
</script>