<template>
  <v-card class="mx-auto" :disabled="ldg">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="login.role_id == 1" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                :to="{
                  name: 'config.update',
                }"
              >
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span v-text="'Editar'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="data">
      <v-row>
        <v-col cols="12" xs="12" md="6">
          <v-row>
            <v-col cols="12">
              <v-card :disabled="data.closure == false">
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <h2 class="text-caption" v-text="'CIERRE DE AÑO'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-icon small :color="data.closure ? 'success' : ''">
                      mdi-circle
                    </v-icon>
                  </v-toolbar-items>
                </v-toolbar>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        label="A partir de"
                        :value="
                          data.closure_start_date[0] +
                          ' ' +
                          data.closure_start_date[1]
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        label="F. recepción"
                        :value="
                          data.closure_reception_date[0] +
                          ' ' +
                          data.closure_reception_date[1]
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="12">
                      <ViewData
                        label="Excepto Red de Proveedores"
                        :value="
                          data.closure_provider_brand_exception
                            ? data.closure_provider_brand_exception
                                .provider_brand
                            : '**NINGUNO**'
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="12">
                      <div
                        class="text-caption font-weight-bold"
                        v-text="'Excepto Proveedor(es)'"
                      />
                      <div
                        v-for="(
                          config_closure_provider_exception, i
                        ) in data.config_closure_provider_exceptions"
                        :key="i"
                        class="text-description"
                      >
                        {{ i + 1 }}. IDP
                        {{ config_closure_provider_exception.provider_id }} |
                        {{
                          config_closure_provider_exception.provider.trade_name
                        }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card :disabled="data.rs_service_bill_doctor_verify == false">
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <h2
                      class="text-caption"
                      v-text="'CONSULTAS PENDIENTES ANALISIS AUTO.'"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-icon
                      small
                      :color="
                        data.rs_service_bill_doctor_verify ? 'success' : ''
                      "
                    >
                      mdi-circle
                    </v-icon>
                  </v-toolbar-items>
                </v-toolbar>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <small>
                        <i>
                          *Se comprueba diariamente a las 00:00:01 hrs, si
                          existen Consultas con Documentación Pendiente de
                          analizar y se intenta ejecutar de manera automatica.
                        </i>
                      </small>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xs="12" md="6">
          <v-row>
            <v-col cols="12">
              <v-card :disabled="data.rha_prog_to_admiss == false">
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <h2
                      class="text-caption"
                      v-text="'PROGRAMACIÓN DE CIGUGIA A INGRESO HOSPITALARIO'"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-icon
                      small
                      :color="data.rha_prog_to_admiss ? 'success' : ''"
                    >
                      mdi-circle
                    </v-icon>
                  </v-toolbar-items>
                </v-toolbar>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <small>
                        <i
                          >*Se comprueba diariamente la fecha de admisión a las
                          00:00:01 hrs</i
                        >
                      </small>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card :disabled="data.rha_discharge == false">
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <h2 class="text-caption" v-text="'TERMINAR INGRESOS'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-icon small :color="data.rha_discharge ? 'success' : ''">
                      mdi-circle
                    </v-icon>
                  </v-toolbar-items>
                </v-toolbar>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Después de (días)"
                        :value="data.rha_discharge_days"
                      />
                    </v-col>
                    <v-col cols="12">
                      <small>
                        <i
                          >*Se comprueba diariamente con su fecha de creación a
                          las 00:00:01 hrs</i
                        >
                      </small>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card :disabled="data.rs_refound_discharge == false">
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <h2
                      class="text-caption"
                      v-text="
                        'TERMINAR REEMBOLSOS CON DOCUMENTACIÓN COMPLEMENTARIA'
                      "
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-icon
                      small
                      :color="data.rs_refound_discharge ? 'success' : ''"
                    >
                      mdi-circle
                    </v-icon>
                  </v-toolbar-items>
                </v-toolbar>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Despues de (días)"
                        :value="data.rs_refound_discharge_days"
                      />
                    </v-col>
                    <v-col cols="12">
                      <small>
                        <i>
                          *Se comprueba diariamente con la fecha en que se
                          rechazo a las 00:00:01 hrs
                        </i>
                      </small>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import { URL_API, headersToken } from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";
import ViewData from "../../components/ViewData.vue";

export default {
  components: {
    FaqDlg,
    ViewData,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      ldg: false,
      data: null,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getData() {
      this.ldg = true;
      this.data = null;

      Axios.get(URL_API + "/config", headersToken(this.login.token)).then(
        (res) => {
          this.data = res.data.data;
          this.ldg = false;
        }
      );
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((res) => {
      this.faqs = res.data.data;
      this.faqs_ldg = false;
    });

    this.getData();
  },
};
</script>