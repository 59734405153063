<template>
  <v-card tile class="mx-auto" :disabled="table_loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: page_route ? `${page_route}.amounts` : 'home',
              params: { id: rha_id },
            }"
          />
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <RhaData :rha_id="rha_id" />
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'REGISTROS'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-tooltip bottom v-if="bill_status_account">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mr-1"
                      v-on="on"
                      icon
                      small
                      color="error"
                      :href="
                        url_pdf +
                        '/rha/amount/' +
                        rha_amount_id +
                        '/benefits/format/' +
                        format
                      "
                      target="_blank"
                    >
                      <v-icon> mdi-file-pdf-box </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Descargar PDF'" />
                </v-tooltip>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="12" v-if="!table_loading">
                  <div>
                    <b v-text="'CPT: '" />
                    {{ rha.cpt ? rha.cpt.cpt : "NO REGISTRADO" }}
                  </div>
                  <div>
                    <b v-text="'Monto Total: '" />
                    {{ numberFormat(total_amount) }}
                  </div>
                  <div>
                    <b v-text="'Hospital CPT: '" />
                    {{ numberFormat(cpt_max_provider) }} Máx /
                    {{ numberFormat(cpt_min_provider) }} Mín.
                  </div>
                  <div v-if="doctor_name">
                    <b v-text="'Médico CPT: '" />
                    {{ numberFormat(cpt_max_doctor) }} Máx /
                    {{ numberFormat(cpt_min_doctor) }} Mín.
                    <br />
                    {{ doctor_name }}
                  </div>
                </v-col>
                <v-col cols="12" md="12">
                  <v-card flat>
                    <v-card-title>
                      <span
                        v-text="
                          `DETALLE ${
                            table_loading ? '' : `(${table_data_total})`
                          }`
                        "
                      />
                      <v-spacer />
                      <v-text-field
                        v-model="table_search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                      />
                    </v-card-title>
                    <v-data-table
                      :headers="table_headers"
                      :items="table_data"
                      :items-per-page="-1"
                      :search="table_search"
                      :loading="table_loading"
                      :item-class="itemRowBackground"
                      item-key="index"
                      :expanded.sync="expanded"
                      show-expand
                      dense
                    >
                      <template v-slot:item.index="{ item }">
                        <span
                          class="font-weight-bold"
                          v-text="item.index + 1"
                        />
                      </template>
                      <template v-slot:item.type="{ item }">
                        <span
                          :class="
                            item.benefit_type
                              ? ''
                              : 'red--text font-weight-medium'
                          "
                          v-text="item.type"
                        />
                      </template>
                      <template v-slot:item.description="{ item }">
                        <span
                          :class="
                            item.benefit &&
                            item.description !=
                              item.benefit.benefit_details[0].description
                              ? 'blue--text'
                              : ''
                          "
                          v-text="item.description"
                        />
                      </template>
                      <template v-slot:item.amount="{ item }">
                        <span
                          v-text="numberFormat(item.amount)"
                          :class="
                            item.benefit &&
                            item.amount ==
                              item.benefit.benefit_details[0].amount * -1
                              ? 'red--text'
                              : ''
                          "
                        />
                        <span
                          v-if="
                            item.percentage_difference &&
                            item.percentage_difference != 0 &&
                            Math.abs(item.amount) !=
                              item.benefit.benefit_details[0].amount
                          "
                          :class="
                            item.percentage_difference > 0
                              ? 'red--text'
                              : 'green--text'
                          "
                        >
                          <small
                            v-text="
                              `(${item.percentage_difference.toFixed(2)}%)`
                            "
                          />
                          <br />
                          <small
                            v-text="
                              numberFormat(
                                item.benefit.benefit_details[0].amount
                              )
                            "
                          />
                        </span>
                      </template>
                      <template v-slot:item.total_amount="{ item }">
                        {{ numberFormat(item.total_amount) }}
                      </template>
                      <template
                        v-slot:item.benefit_insurance_coverages[0].coverage.coverage_type="{
                          item,
                        }"
                      >
                        <span
                          :class="
                            item.benefit_insurance_coverages &&
                            item.benefit_insurance_coverages[0] &&
                            (item.benefit_insurance_coverages[0].coverage.id ===
                              7 ||
                              item.benefit_insurance_coverages[0].coverage
                                .id === 8 ||
                              item.benefit_insurance_coverages[0].coverage
                                .id === 9)
                              ? 'font-weight-bold orange--text'
                              : ''
                          "
                          v-text="
                            item.benefit_insurance_coverages &&
                            item.benefit_insurance_coverages[0]
                              ? item.benefit_insurance_coverages[0].coverage
                                  .coverage_type
                              : '-'
                          "
                        />
                      </template>
                      <template
                        v-slot:item.benefit.benefit_details[0].frequency="{
                          item,
                        }"
                      >
                        <span
                          v-text="
                            item.benefit
                              ? item.benefit.benefit_details[0].frequency
                              : 0
                          "
                        />
                      </template>
                      <template v-slot:item.exception="{ item }">
                        <v-simple-checkbox
                          v-if="item.benefit"
                          v-model="item.exception"
                          :ripple="false"
                        />
                      </template>
                      <template v-slot:item.action="{ item }">
                        <v-tooltip v-if="!item.benefit_type" left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              color="warning"
                              @click.prevent="
                                createBenefitTypeDialog(item.type)
                              "
                            >
                              <v-icon v-text="'mdi-file-upload'" />
                            </v-btn>
                          </template>
                          <span v-text="'Cargar tipo de prestación'" />
                        </v-tooltip>
                        <v-tooltip
                          v-if="item.benefit_type && !item.benefit"
                          left
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              color="error"
                              @click.prevent="formBenefitDialog(item)"
                            >
                              <v-icon v-text="'mdi-tray-arrow-up'" />
                            </v-btn>
                          </template>
                          <span v-text="'Cargar prestación'" />
                        </v-tooltip>
                        <v-tooltip
                          v-if="
                            item.benefit && item.benefit_insurance_coverages
                          "
                          left
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              dark
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              :color="`purple darken-${
                                item.benefit_insurance_coverages[0] ? '1' : '3'
                              }`"
                              @click.prevent="formCoverageDialog(item)"
                            >
                              <v-icon v-text="'mdi-file-certificate'" />
                            </v-btn>
                          </template>
                          <span
                            v-text="
                              `${
                                item.benefit_insurance_coverages[0]
                                  ? 'Actualizar'
                                  : 'Agregar'
                              } cobertura`
                            "
                          />
                        </v-tooltip>
                        <v-tooltip v-if="item.benefit" left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              color="info"
                              @click.prevent="formDescriptionDialog(item)"
                            >
                              <v-icon v-text="'mdi-comment-plus'" />
                            </v-btn>
                          </template>
                          <span v-text="'Agregar descripción'" />
                        </v-tooltip>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td
                          v-if="item.benefit && item.benefit_type"
                          :colspan="headers.length"
                          class="pt-2"
                        >
                          <div
                            v-text="
                              `${item.benefit_type.benefit_type}-${item.benefit.code}`
                            "
                            class="text-h6"
                          />
                          <v-simple-table dense>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th v-text="'#'" />
                                  <th v-text="'Descripción'" />
                                  <th v-text="'Precio'" />
                                  <th v-text="'Frecuencia'" />
                                  <th v-text="'Factura única'" />
                                  <th v-text="'Definición'" />
                                  <th v-text="''" />
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(benefit_detail, i) in item.benefit
                                    .benefit_details"
                                  :key="i"
                                >
                                  <td v-text="i + 1" />
                                  <td v-text="benefit_detail.description" />
                                  <td
                                    v-text="numberFormat(benefit_detail.amount)"
                                  />
                                  <td v-text="benefit_detail.frequency" />
                                  <td v-text="benefit_detail.bill" />
                                  <td v-text="benefit_detail.definition" />
                                  <td>
                                    <v-tooltip v-if="item.benefit" left>
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          class="mr-1"
                                          v-on="on"
                                          fab
                                          x-small
                                          color="secondary"
                                          @click.prevent="
                                            updateDescriptionDialog(
                                              item,
                                              benefit_detail
                                            )
                                          "
                                        >
                                          <v-icon v-text="'mdi-comment-text'" />
                                        </v-btn>
                                      </template>
                                      <span v-text="'Actualizar descripción'" />
                                    </v-tooltip>
                                    <v-tooltip
                                      v-if="
                                        item.benefit &&
                                        benefit_detail.amount != item.amount
                                      "
                                      left
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          class="mr-1"
                                          v-on="on"
                                          fab
                                          x-small
                                          color="warning"
                                          @click.prevent="
                                            updateAmountDialog(
                                              item,
                                              benefit_detail
                                            )
                                          "
                                        >
                                          <v-icon v-text="'mdi-currency-usd'" />
                                        </v-btn>
                                      </template>
                                      <span v-text="'Actualizar precio'" />
                                    </v-tooltip>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <br />
                          <br />
                          <br />
                        </td>
                      </template>
                    </v-data-table>
                    <div class="text-right pt-5 pb-3">
                      <v-btn
                        v-if="
                          table_data_total > 0 &&
                          $store.getters.getLogin.permissions.rha_status_account
                            .create
                        "
                        dark
                        :color="
                          rha_status_account_id > 0
                            ? 'brown darken-1'
                            : 'warning'
                        "
                        elevation="2"
                        x-large
                        @click.prevent="exclusionsDialog"
                      >
                        <v-icon v-text="'mdi-check'" left />
                        Finalizar revisión y guardar exclusiones
                      </v-btn>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- DIALOG FORM BENEFIT start-->
    <v-dialog
      v-model="form_benefit_dialog"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card tile :disabled="loading" :loading="loading">
        <v-toolbar dark>
          <v-toolbar-title v-text="'Cargar prestación'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="formBenefitDialogClose">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="form_benefit_dialog">
          <v-container>
            <v-form
              v-on:submit.prevent
              ref="form_benefit_submit"
              lazy-validation
            >
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-card class="mx-auto">
                    <v-card-title>
                      <h2 class="text-caption" v-text="'PRESTACIÓN'" />
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12" md="12">
                          <ViewData
                            label="Descripción"
                            :value="form_benefit.benefit_details[0].description"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <ViewData label="Código" :value="form_benefit.code" />
                        </v-col>
                        <v-col cols="12" md="6">
                          <ViewData
                            label="Precio"
                            :value="
                              numberFormat(
                                form_benefit.benefit_details[0].amount
                              )
                            "
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete
                            label="Tipo*"
                            v-model="form_benefit.benefit_type_id"
                            :rules="rules.required"
                            :items="benefit_types"
                            item-value="id"
                            :item-text="(item) => item.benefit_type"
                            :loading="benefit_types_loading"
                          />
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            label="Frecuencia"
                            v-model="form_benefit.benefit_details[0].frequency"
                            type="number"
                          />
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            label="Factura única"
                            v-model="form_benefit.benefit_details[0].bill"
                            :rules="rules.text10"
                            type="text"
                          />
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-textarea
                            label="Definición"
                            v-model="form_benefit.benefit_details[0].definition"
                            rows="1"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-btn block color="error" @click.prevent="benefitCreate">
                    <v-icon v-text="'mdi-tray-arrow-up'" left />
                    Aceptar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG FORM BENEFIT end-->
    <!-- DIALOG FORM DESCRIPTION start-->
    <v-dialog
      v-model="form_description_dialog"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card tile :disabled="loading" :loading="loading">
        <v-toolbar dark>
          <v-toolbar-title v-text="'Agregar descripción'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="formDescriptionDialogClose">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="form_description_dialog">
          <v-container>
            <v-form
              v-on:submit.prevent
              ref="form_description_submit"
              lazy-validation
            >
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-card class="mx-auto">
                    <v-card-title>
                      <h2 class="text-caption" v-text="'PRESTACIÓN'" />
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12" md="12">
                          <ViewData
                            label="Descripción"
                            :value="form_description.description"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <ViewData
                            label="Tipo"
                            :value="form_description.type"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <ViewData
                            label="Código"
                            :value="form_description.code"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <ViewData
                            label="Precio"
                            :value="numberFormat(form_description.amount)"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="Frecuencia"
                            v-model="form_description.frequency"
                            type="number"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="Factura única"
                            v-model="form_description.bill"
                            :rules="rules.text10"
                            type="text"
                          />
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-textarea
                            label="Definición"
                            v-model="form_description.definition"
                            rows="1"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-btn block color="info" @click.prevent="descriptionCreate">
                    <v-icon v-text="'mdi-comment-plus'" left />
                    Aceptar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG FORM DESCRIPTION end-->
    <!-- DIALOG UPDATE DESCRIPTION start-->
    <v-dialog
      v-model="update_description_dialog"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card tile :disabled="loading" :loading="loading">
        <v-toolbar dark>
          <v-toolbar-title v-text="'Actualizar descripción'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="update_description_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="update_description_dialog">
          <v-form
            v-on:submit.prevent
            ref="update_description_form"
            lazy-validation
          >
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'PRESTACIÓN'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" md="4">
                        <ViewData
                          label="Tipo"
                          :value="update_description.type"
                        />
                      </v-col>
                      <v-col cols="12" md="4">
                        <ViewData
                          label="Código"
                          :value="update_description.code"
                        />
                      </v-col>
                      <v-col cols="12" md="4">
                        <ViewData
                          label="Precio"
                          :value="numberFormat(update_description.amount)"
                        />
                      </v-col>
                      <v-col cols="12" md="12">
                        <span
                          class="text-caption font-weight-bold"
                          v-text="'Descripción'"
                        />
                        <br />
                        {{ update_description.db_description }}
                        <br />
                        <v-icon
                          large
                          color="orange darken-2"
                          v-text="'mdi-chevron-down'"
                        />
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="update_description.description"
                          :rules="rules.required"
                          type="text"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  color="secondary"
                  @click.prevent="descriptionUpdate"
                >
                  <v-icon v-text="'mdi-comment-text'" left />
                  Aceptar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG UPDATE DESCRIPTION end-->
    <!-- DIALOG UPDATE AMOUNT start-->
    <v-dialog
      v-model="update_amount_dialog"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card tile :disabled="loading" :loading="loading">
        <v-toolbar dark>
          <v-toolbar-title v-text="'Actualizar precio'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="update_amount_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="update_amount_dialog">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'PRESTACIÓN'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" md="12" class="text-center">
                        <ViewData
                          label="Descripción"
                          :value="update_amount.description"
                        />
                      </v-col>
                      <v-col cols="12" md="6" class="text-center">
                        <ViewData label="Tipo" :value="update_amount.type" />
                      </v-col>
                      <v-col cols="12" md="6" class="text-center">
                        <ViewData label="Código" :value="update_amount.code" />
                      </v-col>
                      <v-col cols="12" md="12" class="text-center">
                        <span
                          class="text-caption font-weight-bold"
                          v-text="'Precio'"
                        />
                        <br />
                        {{ numberFormat(update_amount.db_amount) }}
                        <v-icon
                          large
                          color="orange darken-2"
                          v-text="'mdi-chevron-right'"
                        />
                        {{ numberFormat(update_amount.amount) }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-btn block color="warning" @click.prevent="amountUpdate">
                  <v-icon v-text="'mdi-currency-usd'" left />
                  Aceptar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG UPDATE AMOUNT end-->
    <!-- DIALOG FORM COVERAGE start-->
    <v-dialog
      v-model="form_coverage_dialog"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card tile :disabled="loading" :loading="loading">
        <v-toolbar dark>
          <v-toolbar-title
            v-text="
              `${
                form_coverage_dialog &&
                form_coverage.benefit_insurance_coverages[0].id
                  ? 'Actualizar'
                  : 'Agregar'
              } cobertura`
            "
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="formCoverageDialogClose">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="form_coverage_dialog">
          <v-container>
            <v-form
              v-on:submit.prevent
              ref="form_coverage_submit"
              lazy-validation
            >
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-card class="mx-auto">
                    <v-card-title>
                      <h2 class="text-caption" v-text="`PRESTACIÓN`" />
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12" md="12">
                          <ViewData
                            label="Descripción"
                            :value="form_coverage.description"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <ViewData label="Tipo" :value="form_coverage.type" />
                        </v-col>
                        <v-col cols="12" md="6">
                          <ViewData
                            label="Código"
                            :value="form_coverage.code"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <ViewData
                            label="Precio"
                            :value="numberFormat(form_coverage.amount)"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <ViewData
                            label="Aseguradora"
                            :value="form_coverage.insurance_name"
                          />
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-autocomplete
                            label="Cobertura*"
                            v-model="
                              form_coverage.benefit_insurance_coverages[0]
                                .coverage_type_id
                            "
                            :rules="rules.required"
                            :items="coverage_types"
                            item-value="id"
                            :item-text="(item) => item.coverage_type"
                            :loading="coverage_types_loading"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    block
                    color="secondary"
                    @click.prevent="coverageHandle"
                  >
                    <v-icon v-text="'mdi-check'" left />
                    Aceptar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG FORM COVERAGE end-->
    <!-- DIALOG CREATE BENEFIT TYPE start-->
    <v-dialog
      v-model="create_benefit_type_dialog"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card tile :disabled="loading" :loading="loading">
        <v-toolbar dark>
          <v-toolbar-title v-text="'Agregar tipo de prestación'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="create_benefit_type_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="create_benefit_type_dialog">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'PRESTACIÓN'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" md="12" class="text-center">
                        <span
                          class="text-caption font-weight-bold"
                          v-text="'Tipo'"
                        />
                        <br />
                        <v-icon
                          large
                          color="orange darken-2"
                          v-text="'mdi-chevron-down'"
                        />
                        <br />
                        {{ create_benefit_type.benefit_type }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-btn block color="warning" @click.prevent="createBenefitType">
                  <v-icon v-text="'mdi-file-upload'" left />
                  Aceptar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG CREATE BENEFIT TYPE end-->
    <!-- DIALOG EXLUSIONS start-->
    <v-dialog
      v-model="exclusions_dialog"
      scrollable
      persistent
      max-width="1200px"
    >
      <v-card tile :disabled="loading" :loading="loading">
        <v-toolbar dark>
          <v-toolbar-title v-text="'Detalles'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="exclusions_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="exclusions_dialog">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2
                      class="text-caption"
                      v-text="
                        `EXCLUSIONES / POR REVISIÓN SELECCIONADAS (${exceptions_total})`
                      "
                    />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <b v-text="'CPT: '" />
                        {{ rha.cpt ? rha.cpt.cpt : "NO REGISTRADO" }}
                        <br />
                        <b v-text="'Monto Total: '" />
                        {{ numberFormat(total_amount) }}
                      </v-col>
                      <v-col cols="12">
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th v-text="'#'" />
                                <th v-text="'Fecha'" />
                                <th v-text="'Tipo'" />
                                <th v-text="'Código'" />
                                <th v-text="'Descripción'" />
                                <th v-text="'Precio'" />
                                <th v-text="'Cantidad'" />
                                <th v-text="'Total'" />
                                <th v-text="'Cobertura'" />
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(
                                  item, i
                                ) in account_status.rha_account_exceptions"
                                :key="i"
                                v-if="item.active === 1"
                              >
                                <td
                                  class="font-weight-bold"
                                  v-text="item.index + 1"
                                />
                                <td v-text="item.date_time" />
                                <td v-text="item.type" />
                                <td v-text="item.code" />
                                <td v-text="item.description" />
                                <td v-text="numberFormat(item.amount)" />
                                <td v-text="item.quantity" />
                                <td v-text="numberFormat(item.total_amount)" />
                                <td
                                  :class="
                                    item.coverage_type_highlight
                                      ? 'font-weight-bold orange--text'
                                      : ''
                                  "
                                  v-text="item.coverage_type"
                                />
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-btn
                  dark
                  block
                  :color="
                    rha_status_account_id > 0 ? 'brown darken-1' : 'warning'
                  "
                  @click.prevent="submitStatusAccount"
                >
                  <v-icon v-text="'mdi-check'" left />
                  Aceptar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG EXLUSIONS end-->
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  URL_PDF,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { index, show, storeUpdate } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import RhaData from "../../components/RhaData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    RhaData,
    FaqDlg,
  },
  data() {
    return {
      rules: rules(),
      page_route: null,
      rha_id: this.$route.params.id,
      rha_amount_id: this.$route.params.amount_id,
      format: this.$route.params.format,
      rha_status_account_id: parseInt(this.$route.params.rha_status_account_id),
      login: this.$store.getters.getLogin,
      table_data: [],
      table_data_total: 0,
      table_loading: true,
      table_search: "",
      table_headers: [
        {
          text: "#",
          value: "index",
        },
        {
          text: "Fecha",
          filterable: true,
          value: "date_time",
        },
        {
          text: "Tipo",
          filterable: true,
          value: "type",
        },
        {
          text: "Código",
          filterable: true,
          value: "code",
        },
        {
          text: "Descripción",
          filterable: true,
          value: "description",
        },
        {
          text: "Precio",
          filterable: true,
          value: "amount",
        },
        {
          text: "Cantidad",
          filterable: true,
          value: "quantity",
        },
        {
          text: "Total",
          filterable: true,
          value: "total_amount",
        },
        {
          text: "Cobertura",
          filterable: true,
          value: "benefit_insurance_coverages[0].coverage.coverage_type",
        },
        {
          text: "Frecuencia",
          filterable: true,
          value: "benefit.benefit_details[0].frequency",
        },

        {
          text: "Excluidos / Por revisión",
          value: "exception",
          sortable: false,
        },
        { text: "Acciones", value: "action", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      expanded: [],
      rha: {},
      loading: false,
      benefit_types: [],
      benefit_types_loading: true,
      form_benefit: {},
      form_benefit_dialog: false,
      form_description: {},
      form_description_dialog: false,
      update_description: {},
      update_description_dialog: false,
      update_amount: {},
      update_amount_dialog: false,
      form_coverage: {},
      form_coverage_dialog: false,
      create_benefit_type: {},
      create_benefit_type_dialog: false,
      total_amount: 0,
      rha_amount_document_id: 0,
      coverage_types: [],
      coverage_types_loading: false,
      cpt_max_provider: 0,
      cpt_min_provider: 0,
      doctor_name: "",
      cpt_max_doctor: 0,
      cpt_min_doctor: 0,
      account_status: {},
      exclusions_dialog: false,
      exceptions_total: 0,
      bill_status_account: null,
      url_pdf: URL_PDF,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    tableData() {
      this.table_loading = true;
      index(
        `rha/amount/${this.rha_amount_id}/benefits/${this.rha_status_account_id}/format/${this.format}`,
        this.login.token,
        []
      )
        .then((response) => {
          this.total_amount = response.data.total_with_iva;
          this.table_data = response.data.list_benefits;
          this.rha_amount_document_id = response.data.rha_amount_document_id;
          this.table_data_total = this.table_data.length;
          this.cpt_max_provider = response.data.cpt_max_provider;
          this.cpt_min_provider = response.data.cpt_min_provider;
          this.cpt_max_doctor = response.data.cpt_max_doctor;
          this.cpt_min_doctor = response.data.cpt_min_doctor;
          this.doctor_name = response.data.doctor
            ? `${response.data.doctor.name} ${response.data.doctor.first_surname} ${response.data.doctor.second_surname}`
            : null;
          this.bill_status_account = response.data.bill_status_account;
          this.table_loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.$swal.fire(msgAlert("error", "Formato de documento invalido"));
          this.table_loading = false;
        });
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    itemRowBackground: function (item) {
      return item.benefit ? "" : "red lighten-5";
    },
    formBenefitDialog(item) {
      this.loading = true;
      this.form_benefit = {
        id: null,
        code: item.code,
        provider_id: this.rha.provider_id,
        created_by_id: this.login.id,
        updated_by_id: this.login.id,
        benefit_type_id: item.benefit_type ? item.benefit_type.id : null,
        benefit_details: [
          {
            id: null,
            description: item.description,
            amount: Math.abs(item.amount),
            active: 1,
            frequency: 0,
            bill: "",
            definition: "",
          },
        ],
        benefit_includes: [],
        benefit_insurance_coverages: [],
      };

      this.loading = false;
      this.form_benefit_dialog = true;
    },
    formBenefitDialogClose() {
      this.form_benefit_dialog = false;
      this.$refs.form_benefit_submit.resetValidation();
    },
    benefitCreate() {
      if (this.$refs.form_benefit_submit.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma cargar la prestación?"))
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;

              storeUpdate(
                "benefits",
                this.login.token,
                false,
                this.form_benefit,
                this.login.id
              ).then((response) => {
                console.log(response);
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                if (response.success) {
                  this.loading = false;
                  this.form_benefit_dialog = false;

                  this.tableData();
                } else {
                  console.log(response.message);
                }
              });
            }
          });
      }
    },
    formDescriptionDialog(item) {
      this.loading = true;
      this.form_description = {
        id: null,
        benefit_id: item.benefit.id,
        description: item.description,
        amount: item.amount,
        type: item.type,
        code: item.code,
        frequency: 0,
        bill: "",
        definition: "",
        updated_by_id: this.login.id,
        active: 1,
      };

      this.loading = false;
      this.form_description_dialog = true;
    },
    formDescriptionDialogClose() {
      this.form_description_dialog = false;
      this.$refs.form_description_submit.resetValidation();
    },
    descriptionCreate() {
      if (this.$refs.form_description_submit.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma agregar la descripción?"))
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;

              Axios.post(
                URL_API + "/benefits/details",
                this.form_description,
                headersToken(this.login.token)
              ).then((response) => {
                response = response.data;

                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                if (response.success) {
                  this.loading = false;
                  this.form_description_dialog = false;

                  this.tableData();
                } else {
                  console.log(response.message);
                }
              });
            }
          });
      }
    },
    updateDescriptionDialog(item, benefit_detail) {
      this.loading = true;
      this.update_description = {
        id: benefit_detail.id,
        description: item.description,
        updated_by_id: this.login.id,
        type: item.type,
        code: item.code,
        amount: benefit_detail.amount,
        db_description: benefit_detail.description,
      };

      this.loading = false;
      this.update_description_dialog = true;
    },
    descriptionUpdate() {
      if (this.$refs.update_description_form.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma actualizar la descripción?"))
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;

              Axios.post(
                URL_API + "/benefits/details/description",
                this.update_description,
                headersToken(this.login.token)
              ).then((response) => {
                response = response.data;

                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                if (response.success) {
                  this.loading = false;
                  this.update_description_dialog = false;

                  this.tableData();
                } else {
                  console.log(response.message);
                }
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    updateAmountDialog(item, benefit_detail) {
      this.loading = true;
      this.update_amount = {
        id: benefit_detail.id,
        amount: item.amount,
        updated_by_id: this.login.id,
        description: item.description,
        type: item.type,
        code: item.code,
        db_amount: benefit_detail.amount,
      };

      this.loading = false;
      this.update_amount_dialog = true;
    },
    amountUpdate() {
      this.$swal
        .fire(msgConfirm("¿Confirma actualizar monto?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            Axios.post(
              URL_API + "/benefits/details/price",
              this.update_amount,
              headersToken(this.login.token)
            ).then((response) => {
              response = response.data;

              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );

              if (response.success) {
                this.loading = false;
                this.update_amount_dialog = false;

                this.tableData();
              } else {
                console.log(response.message);
              }
            });
          }
        });
    },
    exclusionsDialog() {
      this.table_loading = true;
      let exceptions = [];
      this.exceptions_total = 0;

      for (let item of this.table_data) {
        if (item.exception) {
          this.exceptions_total++;

          exceptions.push({
            id: item.exception_id ? item.exception_id : null,
            row: item.row,
            active: 1,
            index: item.index,
            date_time: item.date_time,
            type: item.type,
            code: item.code,
            description: item.description,
            amount: item.amount,
            quantity: item.quantity,
            total_amount: item.total_amount,
            coverage_type:
              item.benefit_insurance_coverages &&
              item.benefit_insurance_coverages[0]
                ? item.benefit_insurance_coverages[0].coverage.coverage_type
                : "-",
            coverage_type_highlight:
              item.benefit_insurance_coverages &&
              item.benefit_insurance_coverages[0] &&
              (item.benefit_insurance_coverages[0].coverage.id === 7 ||
                item.benefit_insurance_coverages[0].coverage.id === 8 ||
                item.benefit_insurance_coverages[0].coverage.id === 9)
                ? true
                : false,
          });
        } else if (item.exception_id) {
          exceptions.push({
            id: item.exception_id,
            row: item.row,
            active: 0,
          });
        }
      }

      this.account_status = {
        id: this.rha_status_account_id > 0 ? this.rha_status_account_id : null,
        total_amount: this.total_amount,
        finished: true,
        observation: "",
        rha_amount_document_id: this.rha_amount_document_id,
        created_by_id: this.login.id,
        updated_by_id: this.login.id,
        rha_account_exceptions: exceptions,
        status_account_format_id: this.format,
      };

      this.exclusions_dialog = true;

      this.table_loading = false;
    },
    submitStatusAccount() {
      this.$swal
        .fire(msgConfirm("¿Confirma la revisión del Estado de cuenta?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.table_loading = true;

            Axios.post(
              URL_API + "/rha/amount/status/accout",
              this.account_status,
              headersToken(this.login.token)
            ).then((response) => {
              response = response.data;

              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );
              response.success
                ? this.$router.push({
                    name: "admission_request.amounts",
                    params: { id: this.rha_id },
                  })
                : console.log(response.message);
            });
          }
        });
    },
    formCoverageDialog(item) {
      this.loading = true;
      this.form_coverage = {
        benefit_id: item.benefit.id,
        description: item.description,
        amount: item.amount,
        type: item.type,
        code: item.code,
        insurance_name: this.rha.insurance.name,
        benefit_insurance_coverages: [
          {
            id:
              item.benefit_insurance_coverages &&
              item.benefit_insurance_coverages[0]
                ? item.benefit_insurance_coverages[0].id
                : null,
            insurance_id: this.rha.insurance_id,
            coverage_type_id:
              item.benefit_insurance_coverages &&
              item.benefit_insurance_coverages[0]
                ? item.benefit_insurance_coverages[0].coverage.id
                : null,
            active: 1,
          },
        ],
      };

      this.loading = false;
      this.form_coverage_dialog = true;
    },
    formCoverageDialogClose() {
      this.form_coverage_dialog = false;
      this.$refs.form_coverage_submit.resetValidation();
    },
    coverageHandle() {
      if (this.$refs.form_coverage_submit.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma ${
                this.form_coverage.benefit_insurance_coverages.id
                  ? "actualizar"
                  : "agregar"
              } la cobertura?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;

              Axios.post(
                URL_API + "/benefits/details/coverages",
                this.form_coverage,
                headersToken(this.login.token)
              ).then((response) => {
                response = response.data;

                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                if (response.success) {
                  this.loading = false;
                  this.form_coverage_dialog = false;

                  this.tableData();
                } else {
                  console.log(response.message);
                }
              });
            }
          });
      }
    },
    createBenefitTypeDialog(benefit_type) {
      this.loading = true;
      this.create_benefit_type = {
        id: null,
        benefit_type: benefit_type,
        created_by_id: this.login.id,
        updated_by_id: this.login.id,
      };

      this.loading = false;
      this.create_benefit_type_dialog = true;
    },
    createBenefitType() {
      this.$swal
        .fire(msgConfirm("¿Confirma agregar el tipo de prestación?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            Axios.post(
              URL_API + "/benefit/types",
              this.create_benefit_type,
              headersToken(this.login.token)
            ).then((response) => {
              response = response.data;

              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );

              if (response.success) {
                this.benefitTypes();

                this.loading = false;
                this.create_benefit_type_dialog = false;

                this.tableData();
              } else {
                console.log(response.message);
              }
            });
          }
        });
    },
    benefitTypes() {
      catalog("benefit_types", this.login.token).then((response) => {
        this.benefit_types = response;
        this.benefit_types_loading = false;
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.page_route = this.$route.name;
    this.page_route = this.page_route.split(".");
    this.page_route = this.page_route[0];

    show("rha", this.login.token, this.rha_id).then((response) => {
      this.rha = response;
    });

    this.benefitTypes();

    index("coverage/types", this.login.token, []).then((response) => {
      this.coverage_types = response.data;
      this.coverage_types_loading = false;
    });

    this.tableData();
  },
};
</script>