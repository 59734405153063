<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip v-if="true" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: page_route,
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="rs_service">
      <v-row>
        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="`SERVICIO | ${rs_service.folio_full}`"
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="F. creación"
                    :value="rs_service.created_at"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Asegurado"
                    :value="rs_service.rs.insured_full_name"
                  />
                </v-col>

                <v-col v-if="rs_service.doctor" cols="12" xs="12" md="4">
                  <ViewData
                    label="Médico"
                    :value="
                      'IDM ' +
                      rs_service.doctor.id +
                      ' | ' +
                      rs_service.doctor.full_name
                    "
                  />
                </v-col>
                <v-col
                  v-if="rs_service.specialty_type"
                  cols="12"
                  xs="12"
                  md="4"
                >
                  <ViewData
                    label="Especialidad"
                    :value="rs_service.specialty_type.specialty_type"
                  />
                </v-col>
                <v-col
                  v-if="
                    rs_service.consulting_room &&
                    rs_service.consulting_room.provider
                  "
                  cols="12"
                  xs="12"
                  md="4"
                >
                  <ViewData
                    label="Consult. | Hosp."
                    :value="rs_service.consulting_room.provider.trade_name"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Monto"
                    :value="numberFormat(rs_service.benefit_amount)"
                  />
                </v-col>

                <v-col v-if="rs_service.rs.rs_documents" cols="12">
                  <v-row dense>
                    <v-col
                      cols="12"
                      v-for="(rs_document, i) in rs_service.rs.rs_documents"
                      :key="i"
                    >
                      <v-row>
                        <v-col cols="12">
                          <span
                            class="text-caption font-weight-bold"
                            v-text="`Documento (${i + 1})`"
                          />
                          <br />
                          <span
                            class="text-description"
                            v-text="rs_document.description"
                          />

                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                small
                                v-on="on"
                                :href="
                                  url_documents +
                                  '/rs_documents/' +
                                  rs_document.url
                                "
                                target="_blank"
                              >
                                <v-icon small>mdi-eye</v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver'" />
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col v-if="rs_service.rs_service_insured_bill" cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="
                    `COBRO | ${rs_service.rs_service_insured_bill.folio} | ${
                      !rs_service.rs_service_insured_bill.stamp_id
                        ? 'SIN'
                        : 'CON'
                    } FACTURA`
                  "
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-tooltip
                  left
                  v-if="rs_service.rs_service_insured_bill.stamp_id"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="cyan darken-3"
                      class="ml-1"
                      icon
                      @click.prevent="
                        downloadFile(
                          rs_service.rs_service_insured_bill.stamp_id,
                          'pdf'
                        )
                      "
                    >
                      <v-icon> mdi-file-download </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Descargar PDF'" />
                </v-tooltip>
                <v-tooltip
                  left
                  v-if="rs_service.rs_service_insured_bill.stamp_id"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="teal darken-2"
                      class="ml-1"
                      icon
                      @click.prevent="
                        downloadFile(
                          rs_service.rs_service_insured_bill.stamp_id,
                          'xml'
                        )
                      "
                    >
                      <v-icon> mdi-xml </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Descargar XML'" />
                </v-tooltip>
                <v-tooltip left v-else>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="teal darken-2"
                      class="ml-1"
                      icon
                      :href="
                        url_pdf +
                        '/comprobante/pago/' +
                        rs_service.rs_service_insured_bill.id
                      "
                      target="_blank"
                    >
                      <v-icon> mdi-file-certificate </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Descargar Comprobante'" />
                </v-tooltip>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Paciente | C. electrónico"
                    :value="rs_service.rs.email"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Monto*"
                    :value="
                      numberFormat(
                        rs_service.rs_service_insured_bill.pay_amount
                      )
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Total cobrado"
                    :value="
                      numberFormat(
                        rs_service.rs_service_insured_bill.insurance_pay_amount
                      )
                    "
                  />
                </v-col>
              </v-row>
              <v-row v-if="rs_service.rs_service_insured_bill.stamp_id" dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Razón social"
                    :value="rs_service.rs_service_insured_bill.legal_name"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="RFC"
                    :value="rs_service.rs_service_insured_bill.code_tax"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Código postal"
                    :value="rs_service.rs_service_insured_bill.legal_zip"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Régimen"
                    :value="
                      rs_service.rs_service_insured_bill.fiscal_regime
                        .fiscal_regime
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Uso CFDI"
                    :value="
                      rs_service.rs_service_insured_bill.fiscal_use.fiscal_use
                    "
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Forma de pago"
                    :value="
                      rs_service.rs_service_insured_bill.fiscal_method_payment
                        .fiscal_method_payment
                    "
                  />
                </v-col>
                <v-col
                  v-if="
                    rs_service.rs_service_insured_bill
                      .fiscal_method_payment_id == 4 ||
                    rs_service.rs_service_insured_bill
                      .fiscal_method_payment_id == 18
                  "
                  cols="12"
                  xs="12"
                  md="3"
                >
                  <ViewData
                    label="Núm. de aprobación"
                    :value="rs_service.rs_service_insured_bill.approval_number"
                  />
                </v-col>
                <v-col
                  v-if="
                    rs_service.rs_service_insured_bill
                      .fiscal_method_payment_id == 4 ||
                    rs_service.rs_service_insured_bill
                      .fiscal_method_payment_id == 18
                  "
                  cols="12"
                  xs="12"
                  md="3"
                >
                  <ViewData
                    label="Tarjeta (4 últ. dig.)"
                    :value="rs_service.rs_service_insured_bill.card"
                  />
                </v-col>
                <v-col cols="12">
                  <ViewData
                    label="Observación"
                    :value="rs_service.rs_service_insured_bill.observation"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card tile :loading="step_loading" :disabled="step_loading">
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'DOCUMENTACIÓN'" />
              </v-toolbar-title>
            </v-toolbar>
            <v-spacer />
            <v-card-text class="px-0 pb-0 pt-1">
              <v-stepper v-model="step" vertical flat>
                <v-stepper-step :complete="step > 1" step="1">
                  Archivos
                </v-stepper-step>
                <v-stepper-content step="1">
                  <v-form v-on:submit.prevent ref="form_data" lazy-validation>
                    <v-row dense>
                      <v-col
                        cols="12"
                        xs="12"
                        :md="rs_service.rs_service_insured_bill ? 4 : 3"
                      >
                        <v-file-input
                          v-model="data.document_bill_0"
                          label="Factura (pdf)*"
                          accept=".pdf"
                          show-size
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        :md="rs_service.rs_service_insured_bill ? 4 : 3"
                      >
                        <v-file-input
                          v-model="data.document_bill_xml_0"
                          label="Factura (xml)*"
                          accept=".xml"
                          show-size
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        :md="rs_service.rs_service_insured_bill ? 4 : 3"
                      >
                        <v-text-field
                          label="Monto total con IVA*"
                          v-model="data.documents[0].amount_typed"
                          type="number"
                          prepend-icon="mdi-currency-usd"
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col
                        v-if="!rs_service.rs_service_insured_bill"
                        cols="12"
                        xs="12"
                        md="3"
                      >
                        <v-file-input
                          v-model="data.document_letter_auth_0"
                          label="Carta autorización (pdf)*"
                          accept=".pdf"
                          show-size
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="6" />
                    <v-col cols="12" sm="12" md="6">
                      <v-btn
                        block
                        color="primary"
                        @click.prevent="docVerify"
                        small
                      >
                        Continuar
                        <v-icon right> mdi-chevron-right </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-step :complete="step > 2" step="2">
                  Verificación
                </v-stepper-step>
                <v-stepper-content step="2">
                  <v-row dense v-if="step == 2">
                    <v-col cols="12">
                      <v-alert v-if="data.bills_repeat" type="error" dense>
                        <div
                          class="text-center"
                          v-text="
                            'Una o varias facturas XML cargadas estan repetidas'
                          "
                        />
                      </v-alert>
                      <v-alert
                        v-if="
                          data.valid_rs_service_insured_bill_pay_amount == false
                        "
                        type="error"
                        dense
                      >
                        <div
                          class="text-center"
                          v-text="
                            'El monto subtotal de la factura no concuerda con el monto del cobro'
                          "
                        />
                      </v-alert>
                    </v-col>
                    <!-- DOCUMENTS START -->
                    <v-col
                      cols="12"
                      v-for="(document, i) in data.documents"
                      :key="i"
                    >
                      <v-row dense>
                        <v-col cols="12" class="grey darken-1 white--text">
                          <h3 v-text="`Factura (${i + 1})`" class="pl-1" />
                        </v-col>

                        <v-col cols="12">
                          <v-alert
                            v-if="document.xml && document.bill_exist"
                            type="error"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                `El folio de esta factura ya ha sido registrado para el servicio ${document.bill_exist_rs_folio}`
                              "
                            />
                          </v-alert>
                          <v-alert v-if="!document.xml" type="error" dense>
                            <div
                              class="text-center"
                              v-text="
                                'La factura XML no cuenta con un formato valido de lectura SAT'
                              "
                            />
                          </v-alert>
                          <v-alert
                            v-if="document.xml && !document.amount_typed_valid"
                            type="error"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                'El monto total con IVA no es igual al Total de la factura'
                              "
                            />
                          </v-alert>
                          <v-alert
                            v-if="
                              document.xml && !document.valid_receiver_code_tax
                            "
                            type="error"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                'El RFC del receptor no concuerda con el de Sinergia Médica'
                              "
                            />
                          </v-alert>
                          <v-alert
                            v-if="document.xml && !document.valid_receiver_name"
                            type="warning"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                'La razón social del receptor no concuerda con el de Sinergia Médica'
                              "
                            />
                          </v-alert>
                          <v-alert
                            v-if="
                              document.xml &&
                              !document.valid_transmitter_code_tax
                            "
                            type="error"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                'El RFC del emisor no concuerda con los datos registrados en nuestra base de datos'
                              "
                            />
                          </v-alert>
                          <!-- <v-alert
                            v-if="
                              document.xml && !document.valid_transmitter_name
                            "
                            type="error"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                'La razón social del emisor no concuerda con los datos registrados en nuestra base de datos'
                              "
                            />
                          </v-alert> -->
                          <v-alert
                            v-if="document.xml && !document.valid_bill"
                            type="error"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                'Los cálculos de ISR y/o IVA retenidos no son correctos'
                              "
                            />
                          </v-alert>
                          <v-alert
                            v-if="document.xml && !document.valid_regimen"
                            type="error"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="'El régimen legal no es válido'"
                            />
                          </v-alert>
                        </v-col>
                        <v-col cols="12" v-if="document.xml">
                          <v-row dense>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="Receptor"
                                :value="document.xml.Receptor.attr.Nombre"
                                :class="
                                  document.valid_receiver_name
                                    ? ''
                                    : 'orange--text text--darken-3'
                                "
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="RFC"
                                :value="document.xml.Receptor.attr.Rfc"
                                :class="
                                  document.valid_receiver_code_tax
                                    ? ''
                                    : 'red--text text--darken-3'
                                "
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="Fecha y hora de expedición"
                                :value="document.xml_stamped_date"
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                :class="
                                  !document.status_account_valid &&
                                  !document.amount_typed_valid
                                    ? 'red--text text--darken-3'
                                    : ''
                                "
                                label="Monto total con IVA"
                                :value="numberFormat(document.amount_typed)"
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="Emisor"
                                :value="document.xml.Emisor.attr.Nombre"
                                :class="
                                  document.valid_transmitter_name
                                    ? ''
                                    : 'red--text text--darken-3'
                                "
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="RFC"
                                :value="document.xml.Emisor.attr.Rfc"
                                :class="
                                  document.valid_transmitter_code_tax
                                    ? ''
                                    : 'red--text text--darken-3'
                                "
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="Folio"
                                :value="document.xml_folio"
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="Régimen fiscal"
                                :value="document.xml.Emisor.attr.RegimenFiscal"
                                :class="
                                  document.valid_regimen
                                    ? ''
                                    : 'red--text text--darken-3'
                                "
                              />
                            </v-col>
                            <v-col cols="12">
                              <span
                                class="text-caption font-weight-bold"
                                v-text="'Conceptos'"
                              />
                              <small>
                                <v-simple-table dense>
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th v-text="'#'" />
                                        <th v-text="'Unidad'" />
                                        <th v-text="'Cantidad'" />
                                        <th v-text="'Clave'" />
                                        <th v-text="'Descripción'" />
                                        <th v-text="'Precio'" />
                                        <th v-text="'Importe'" />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="(concept, j) in document.xml
                                          .Conceptos.Concepto"
                                        :key="j"
                                      >
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="j + 1"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="concept.attr.ClaveUnidad"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="concept.attr.Cantidad"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="concept.attr.ClaveProdServ"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="concept.attr.Descripcion"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(
                                              concept.attr.ValorUnitario
                                            )
                                          "
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(concept.attr.Importe)
                                          "
                                        />
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="'Subtotal'"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(
                                              document.xml.attr.SubTotal
                                            )
                                          "
                                        />
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="'Descuento'"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(
                                              document.xml.attr.Descuento
                                                ? document.xml.attr.Descuento
                                                : 0
                                            )
                                          "
                                        />
                                      </tr>
                                      <tr v-if="document.valid_iva != null">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                          :class="`text-caption font-weight-bold${
                                            document.valid_iva
                                              ? ''
                                              : ' red--text text--darken-3'
                                          }`"
                                          v-text="'Impuestos Trasladados'"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(document.importe_iva)
                                          "
                                        />
                                      </tr>
                                      <tr v-if="document.valid_isr != null">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                          :class="`text-caption font-weight-bold${
                                            document.valid_isr
                                              ? ''
                                              : ' red--text text--darken-3'
                                          }`"
                                          v-text="'Impuestos Retenidos'"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(
                                              document.importe_isr
                                                ? document.importe_isr
                                                : 0
                                            )
                                          "
                                        />
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="'IVA'"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            document.xml.Impuestos &&
                                            document.xml.Impuestos.attr
                                              .TotalImpuestosTrasladados
                                              ? numberFormat(
                                                  document.xml.Impuestos.attr
                                                    .TotalImpuestosTrasladados
                                                )
                                              : numberFormat(0)
                                          "
                                        />
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="'Total'"
                                        />
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="
                                            numberFormat(document.xml_amount)
                                          "
                                        />
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </small>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!-- DOCUMENTS END -->
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-btn block @click.prevent="docFormData">
                        <v-icon left> mdi-chevron-left </v-icon>
                        Atrás
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        block
                        :color="data.to_step_3 ? 'primary' : ''"
                        @click.prevent="docBeforeSubmit"
                      >
                        Continuar
                        <v-icon right> mdi-chevron-right </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-step :complete="step > 3" step="3">
                  Envío
                </v-stepper-step>
                <v-stepper-content step="3">
                  <v-row v-if="step == 3">
                    <v-col cols="12" v-if="!data.to_stamp">
                      <v-alert type="warning" dense>
                        <div
                          class="text-center"
                          v-text="
                            'La documentación a cargar pasara a un proceso de revisión'
                          "
                        />
                        <div
                          v-if="!data.valid_assured_amount"
                          class="text-center"
                          v-text="
                            '*El monto de la factura supera el monto asegurado'
                          "
                        />
                        <!-- <div
                          v-if="!data.valid_receiver_names"
                          class="text-center"
                          v-text="
                            '*La razón social del receptor de una o varias facturas no es correcta'
                          "
                        /> -->
                      </v-alert>
                    </v-col>
                    <v-col cols="12" v-if="!rs_service.rs_service_insured_bill">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Identificación'"
                      />
                      <v-icon
                        right
                        small
                        :color="
                          (store_mode && data.document_credential_0) ||
                          (!store_mode && data.url_credential)
                            ? 'success'
                            : 'warning'
                        "
                      >
                        {{
                          (store_mode && data.document_credential_0) ||
                          (!store_mode && data.url_credential)
                            ? "mdi-check"
                            : "mdi-alert"
                        }}
                      </v-icon>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Total factura'"
                      />
                      <span v-text="numberFormat(data.xmls_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Total IVA'"
                      />
                      <span v-text="numberFormat(data.xmls_tax_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3" />
                    <v-col cols="12" sm="12" md="3">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Total a pagar'"
                      />
                      <span v-text="numberFormat(data.pay_amount)" />
                    </v-col>
                    <v-col
                      cols="12"
                      v-for="(document, i) in data.documents"
                      :key="i"
                    >
                      <v-row dense>
                        <v-col cols="12" class="grey darken-3 white--text">
                          <h4
                            v-text="
                              `Factura (${i + 1}) | ${document.xml_folio}`
                            "
                            class="pl-1"
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="2">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Factura PDF'"
                          />
                          <v-icon
                            right
                            small
                            :color="
                              (store_mode && data['document_bill_' + i]) ||
                              (!store_mode && document.url_bill)
                                ? 'success'
                                : 'warning'
                            "
                          >
                            {{
                              (store_mode && data["document_bill_" + i]) ||
                              (!store_mode && document.url_bill)
                                ? "mdi-check"
                                : "mdi-alert"
                            }}
                          </v-icon>
                        </v-col>
                        <v-col cols="12" sm="12" md="2">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Factura XML'"
                          />
                          <v-icon
                            right
                            small
                            :color="document.xml_valid ? 'success' : 'warning'"
                          >
                            {{ document.xml_valid ? "mdi-check" : "mdi-alert" }}
                          </v-icon>
                        </v-col>
                        <v-col cols="12" sm="12" md="2">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'RFC y razon social'"
                          />
                          <v-icon
                            right
                            small
                            :color="
                              document.valid_receiver_code_tax &&
                              document.valid_receiver_name
                                ? 'success'
                                : 'warning'
                            "
                          >
                            {{
                              document.valid_receiver_code_tax &&
                              document.valid_receiver_name
                                ? "mdi-check"
                                : "mdi-alert"
                            }}
                          </v-icon>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Monto XML'"
                          />
                          <span v-text="numberFormat(document.xml_amount)" />
                        </v-col>
                        <v-col
                          v-if="!rs_service.rs_service_insured_bill"
                          cols="12"
                          sm="12"
                          md="2"
                        >
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Carta autorización'"
                          />
                          <v-icon
                            right
                            small
                            :color="
                              (store_mode &&
                                data['document_letter_auth_' + i]) ||
                              (!store_mode && document.url_letter_auth)
                                ? 'success'
                                : 'warning'
                            "
                          >
                            {{
                              (store_mode &&
                                data["document_letter_auth_" + i]) ||
                              (!store_mode && document.url_letter_auth)
                                ? "mdi-check"
                                : "mdi-alert"
                            }}
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="6">
                      <v-btn block @click.prevent="step = 2">
                        <v-icon left> mdi-chevron-left </v-icon>
                        Atrás
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn block color="success" @click.prevent="docSubmit">
                        Cargar
                        <v-icon right> mdi-upload </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  rules,
  msgAlert,
  msgConfirm,
  toFormData,
  URL_DOCUMENTS,
  URL_PDF,
} from "../../control";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      rs_service_id: parseInt(this.$route.params.rs_service_id),
      login: this.$store.getters.getLogin,
      loading: true,
      rs_service: null,
      store_mode: null,
      data: null,
      step_loading: false,
      step: 1,
      rules: rules(),
      url_documents: URL_DOCUMENTS,
      url_pdf: URL_PDF,
      page_route: "caja_medicos",
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    downloadFile(stamped_id, extension) {
      this.service_payment_info_dialog_loading = true;

      Axios.get(
        URL_API + `/bills/stamped/${stamped_id}/files/${extension}/2`,
        headersToken(this.login.token)
      ).then((resp) => {
        this.$swal.fire(
          msgAlert(resp.data.success ? "success" : "error", resp.data.message)
        );

        if (resp.data.success) {
          const linkSource = `data:application/${extension};base64,${resp.data.data}`;
          const downloadLink = document.createElement("a");
          const fileName = `sm_bill_${stamped_id}.${extension}`;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          console.log(resp.data.message);
        }

        this.service_payment_info_dialog_loading = false;
      });
    },
    init() {
      Axios.get(
        URL_API + `/rss/services/${this.rs_service_id}`,
        headersToken(this.login.token)
      ).then((resp) => {
        if (resp.data.success) {
          this.rs_service = resp.data.data;

          if (!this.rs_service.rs_service_bill) {
            this.store_mode = true;

            this.data = {
              id: null,
              active: true,
              rs_service_id: this.rs_service_id,
              document_bill_0: null,
              document_bill_xml_0: null,
              document_letter_auth_0: null,
              store_mode: this.store_mode,
              doctor_id: this.rs_service.doctor_id,
              //-------------
              pay_amount: null,
              insurance_pay_amount: null,
              taxed: null,
              xmls_valid: null,
              xmls_subtotal_amount: null,
              xmls_discount_amount: null,
              xmls_tax_amount: null,
              xmls_amount: null,
              notes_amount: null,
              notes_subtotal_amount: null,
              valid_receiver_code_taxs: null,
              valid_receiver_names: null,
              valid_amounts: null,
              to_stamp: null,
              bills_repeat: null,
              bills_exist: null,
              notes_repeat: null,
              notes_exist: null,
              to_step_3: null,
              valid_transmitter_code_taxs: null,
              valid_transmitter_names: null,
              valid_bills: null,
              valid_regimens: null,
              amount_typed_valids: null,
              valid_assured_amount: null,
              valid_rs_service_insured_bill_pay_amount: null,
              bills_with_notes: null,
              deductibles_amount: null,
              coinsurances_amount: null,
              discounts_amount: null,
              //-------------
              documents: [
                {
                  id: null,
                  active: true,
                  url_bill_xml: null,
                  //-------------
                  amount_typed: "0",
                  xml_folio: null,
                  xml_stamped_date: null,
                  xml_amount: null,
                  valid_receiver_code_tax: null,
                  valid_receiver_name: null,
                  notes_amount: null,
                  xml: null,
                  bill_exist: null,
                  bill_exist_rs_folio: null,
                  xml_tax_amount: null,
                  amount_typed_valid: null,
                  xml_valid: null,
                  valid_transmitter_code_tax: null,
                  valid_transmitter_name: null,
                  valid_bill: null,
                  valid_regimen: null,
                  valid_iva: null,
                  valid_isr: null,
                  importe_isr: null,
                  importe_iva: null,
                  xml_subtotal_amount: null,
                  xml_iva_amount: null,
                  xml_isr_amount: null,
                  fiscal_regime_id: null,
                  fiscal_regime: null,
                  //-------------
                  notes: [],
                },
              ],
            };
          } else {
            this.store_mode = false;
            this.data = this.rs_service.rs_service_bill;
            this.data.documents = this.data.rs_service_bill_documents;
          }

          this.loading = false;
        } else {
          console.log(resp.data.message);
        }
      });
    },
    docVerify() {
      if (this.$refs.form_data.validate()) {
        this.step_loading = true;

        Axios.post(
          `${URL_API}/bills/rs/xml/to/json`,
          toFormData(this.data),
          headersToken(this.login.token)
        ).then((resp) => {
          if (resp.data.success) {
            resp = resp.data.data;

            this.data.pay_amount = resp.pay_amount;
            this.data.insurance_pay_amount = resp.insurance_pay_amount;
            this.data.taxed = resp.taxed;
            this.data.xmls_valid = resp.xmls_valid;
            this.data.xmls_subtotal_amount = resp.xmls_subtotal_amount;
            this.data.xmls_discount_amount = resp.xmls_discount_amount;
            this.data.xmls_tax_amount = resp.xmls_tax_amount;
            this.data.xmls_amount = resp.xmls_amount;
            this.data.notes_amount = resp.notes_amount;
            this.data.notes_subtotal_amount = resp.notes_subtotal_amount;
            this.data.valid_receiver_code_taxs = resp.valid_receiver_code_taxs;
            this.data.valid_receiver_names = resp.valid_receiver_names;
            this.data.valid_amounts = resp.valid_amounts;
            this.data.to_stamp = resp.to_stamp;
            this.data.bills_repeat = resp.bills_repeat;
            this.data.bills_exist = resp.bills_exist;
            this.data.notes_repeat = resp.notes_repeat;
            this.data.notes_exist = resp.notes_exist;
            this.data.to_step_3 = resp.to_step_3;
            this.data.valid_transmitter_code_taxs =
              resp.valid_transmitter_code_taxs;
            this.data.valid_transmitter_names = resp.valid_transmitter_names;
            this.data.valid_bills = resp.valid_bills;
            this.data.valid_regimens = resp.valid_regimens;
            this.data.amount_typed_valids = resp.amount_typed_valids;
            this.data.valid_assured_amount = resp.valid_assured_amount;
            this.data.valid_rs_service_insured_bill_pay_amount =
              resp.valid_rs_service_insured_bill_pay_amount;
            this.data.bills_with_notes = resp.bills_with_notes;
            this.data.deductibles_amount = resp.deductibles_amount;
            this.data.coinsurances_amount = resp.coinsurances_amount;
            this.data.discounts_amount = resp.discounts_amount;

            for (let i = 0; i < this.data.documents.length; i++) {
              this.data.documents[i].amount_typed =
                resp.documents[i].amount_typed;
              this.data.documents[i].xml_folio = resp.documents[i].xml_folio;
              this.data.documents[i].xml_stamped_date =
                resp.documents[i].xml_stamped_date;
              this.data.documents[i].xml_amount = resp.documents[i].xml_amount;
              this.data.documents[i].valid_receiver_code_tax =
                resp.documents[i].valid_receiver_code_tax;
              this.data.documents[i].valid_receiver_name =
                resp.documents[i].valid_receiver_name;
              this.data.documents[i].notes_amount =
                resp.documents[i].notes_amount;
              this.data.documents[i].xml = resp.documents[i].xml;
              this.data.documents[i].bill_exist = resp.documents[i].bill_exist;
              this.data.documents[i].bill_exist_rs_folio =
                resp.documents[i].bill_exist_rs_folio;
              this.data.documents[i].xml_tax_amount =
                resp.documents[i].xml_tax_amount;
              this.data.documents[i].amount_typed_valid =
                resp.documents[i].amount_typed_valid;
              this.data.documents[i].xml_valid = resp.documents[i].xml_valid;
              this.data.documents[i].valid_transmitter_code_tax =
                resp.documents[i].valid_transmitter_code_tax;
              this.data.documents[i].valid_transmitter_name =
                resp.documents[i].valid_transmitter_name;
              this.data.documents[i].valid_bill = resp.documents[i].valid_bill;
              this.data.documents[i].valid_regimen =
                resp.documents[i].valid_regimen;
              this.data.documents[i].valid_iva = resp.documents[i].valid_iva;
              this.data.documents[i].valid_isr = resp.documents[i].valid_isr;
              this.data.documents[i].importe_isr =
                resp.documents[i].importe_isr;
              this.data.documents[i].importe_iva =
                resp.documents[i].importe_iva;
              this.data.documents[i].xml_subtotal_amount =
                resp.documents[i].xml_subtotal_amount;
              this.data.documents[i].xml_iva_amount =
                resp.documents[i].xml_iva_amount;
              this.data.documents[i].xml_isr_amount =
                resp.documents[i].xml_isr_amount;
              this.data.documents[i].fiscal_regime_id =
                resp.documents[i].fiscal_regime_id;
              this.data.documents[i].fiscal_regime =
                resp.documents[i].fiscal_regime;
            }

            this.step = 2;
          } else {
            this.$swal.fire(msgAlert("error", data.data.message));
            console.log(data.data.message);
          }

          this.step_loading = false;
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },

    docDefVals() {
      this.data.pay_amount = null;
      this.data.insurance_pay_amount = null;
      this.data.taxed = null;
      this.data.xmls_valid = null;
      this.data.xmls_subtotal_amount = null;
      this.data.xmls_discount_amount = null;
      this.data.xmls_tax_amount = null;
      this.data.xmls_amount = null;
      this.data.notes_amount = null;
      this.data.notes_subtotal_amount = null;
      this.data.valid_receiver_code_taxs = null;
      this.data.valid_receiver_names = null;
      this.data.valid_amounts = null;
      this.data.to_stamp = null;
      this.data.bills_repeat = null;
      this.data.bills_exist = null;
      this.data.notes_repeat = null;
      this.data.notes_exist = null;
      this.data.to_step_3 = null;
      this.data.valid_transmitter_code_taxs = null;
      this.data.valid_transmitter_names = null;
      this.data.valid_bills = null;
      this.data.valid_regimens = null;
      this.data.amount_typed_valids = null;
      this.data.valid_assured_amount = null;
      this.data.valid_rs_service_insured_bill_pay_amount = null;
      this.data.bills_with_notes = null;
      this.data.deductibles_amount = null;
      this.data.coinsurances_amount = null;
      this.data.discounts_amount = null;

      for (let i = 0; i < this.data.documents.length; i++) {
        this.data.documents[i].xml_folio = null;
        this.data.documents[i].xml_stamped_date = null;
        this.data.documents[i].xml_amount = null;
        this.data.documents[i].valid_receiver_code_tax = null;
        this.data.documents[i].valid_receiver_name = null;
        this.data.documents[i].notes_amount = null;
        this.data.documents[i].xml = null;
        this.data.documents[i].bill_exist = null;
        this.data.documents[i].bill_exist_rs_folio = null;
        this.data.documents[i].xml_tax_amount = null;
        this.data.documents[i].amount_typed_valid = null;
        this.data.documents[i].xml_valid = null;
        this.data.documents[i].valid_transmitter_code_tax = null;
        this.data.documents[i].valid_transmitter_name = null;
        this.data.documents[i].valid_bill = null;
        this.data.documents[i].valid_regimen = null;
        this.data.documents[i].valid_iva = null;
        this.data.documents[i].valid_isr = null;
        this.data.documents[i].importe_isr = null;
        this.data.documents[i].importe_iva = null;
        this.data.documents[i].xml_subtotal_amount = null;
        this.data.documents[i].xml_iva_amount = null;
        this.data.documents[i].xml_isr_amount = null;
        this.data.documents[i].fiscal_regime_id = null;
        this.data.documents[i].fiscal_regime = null;
      }
    },
    docFormData() {
      this.step_loading = true;
      this.docDefVals();
      this.step_loading = false;
      this.step = 1;
    },
    docBeforeSubmit() {
      if (this.data.to_step_3) {
        this.step = 3;
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Existen detalles remarcados en rojo que deben ser atendidos para continuar"
          )
        );
      }
    },
    docSubmit() {
      this.$swal
        .fire(msgConfirm(`¿Confirma cargar la documentación?`))
        .then((resp) => {
          if (resp.isConfirmed) {
            this.step_loading = true;

            Axios.post(
              `${URL_API}/rss/services/bills`,
              toFormData(this.data),
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.$router.push({
                  name: this.page_route,
                });
              } else {
                console.log(resp.data.message);
              }

              this.step_loading = false;
            });
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.page_route =
      this.$route.name == "servicios.documentacion"
        ? "servicios"
        : this.$route.name == "caja_medicos.documentacion"
        ? "caja_medicos"
        : "pend_cobros";

    this.init();
  },
};
</script>