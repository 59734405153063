<template>
  <v-card class="mx-auto" :disabled="ldg">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'config',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right" />
      </v-row>
    </v-card-title>
    <v-card-text v-if="data">
      <v-form v-on:submit.prevent ref="data_form" lazy-validation>
        <v-row>
          <v-col cols="12" xs="12" md="6">
            <v-row>
              <v-col cols="12">
                <v-card>
                  <v-toolbar elevation="1" dense>
                    <v-toolbar-title>
                      <h2 class="text-caption" v-text="'CIERRE DE AÑO'" />
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                      <v-switch
                        v-model="data.closure"
                        class="pt-3"
                        color="success"
                        dense
                      />
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" xs="12" md="6">
                        <DatePicker
                          label="A partir de"
                          :model.sync="data.closure_start_date[0]"
                          clean
                          all_date
                          :rules="rules.required"
                        />
                        <v-text-field
                          v-model="data.closure_start_date[1]"
                          type="time"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <DatePicker
                          label="F. recepción"
                          :model.sync="data.closure_reception_date[0]"
                          clean
                          all_date
                          :rules="rules.required"
                        />
                        <v-text-field
                          v-model="data.closure_reception_date[1]"
                          type="time"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="12">
                        <v-autocomplete
                          label="Excepto Red de Proveedores"
                          v-model="data.closure_provider_brand_exception_id"
                          :items="provider_brands"
                          :loading="provider_brands_ldg"
                          item-value="id"
                          :item-text="(v) => v.provider_brand"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="12">
                        <v-autocomplete
                          label="Excepto Proveedor(es)"
                          v-model="data.config_closure_provider_exceptions_ids"
                          :items="providers"
                          :loading="providers_ldg"
                          item-value="id"
                          :item-text="
                            (v) => 'IDP ' + v.id + ' | ' + v.trade_name
                          "
                          dense
                          multiple
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card>
                  <v-toolbar elevation="1" dense>
                    <v-toolbar-title>
                      <h2
                        class="text-caption"
                        v-text="'CONSULTAS PENDIENTES ANALISIS AUTO.'"
                      />
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                      <v-switch
                        v-model="data.rs_service_bill_doctor_verify"
                        class="pt-3"
                        color="success"
                        dense
                      />
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <small>
                          <i>
                            *Se comprueba diariamente a las 00:00:01 hrs, si
                            existen Consultas con Documentación Pendiente de
                            analizar y se intenta ejecutar de manera automatica.
                          </i>
                        </small>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xs="12" md="6">
            <v-row>
              <v-col cols="12">
                <v-card>
                  <v-toolbar elevation="1" dense>
                    <v-toolbar-title>
                      <h2
                        class="text-caption"
                        v-text="
                          'PROGRAMACIÓN DE CIGUGIA A INGRESO HOSPITALARIO'
                        "
                      />
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                      <v-switch
                        v-model="data.rha_prog_to_admiss"
                        class="pt-3"
                        color="success"
                        dense
                      />
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <small>
                          <i
                            >*Se comprueba diariamente la fecha de admisión a
                            las 00:00:01 hrs</i
                          >
                        </small>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card>
                  <v-toolbar elevation="1" dense>
                    <v-toolbar-title>
                      <h2 class="text-caption" v-text="'TERMINAR INGRESOS'" />
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                      <v-switch
                        v-model="data.rha_discharge"
                        class="pt-3"
                        color="success"
                        dense
                      />
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          label="Despues de (días)"
                          v-model="data.rha_discharge_days"
                          min="1"
                          max="365"
                          type="number"
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12">
                        <small>
                          <i
                            >*Se comprueba diariamente con su fecha de creación
                            a las 00:00:01 hrs</i
                          >
                        </small>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card>
                  <v-toolbar elevation="1" dense>
                    <v-toolbar-title>
                      <h2
                        class="text-caption"
                        v-text="
                          'TERMINAR REEMBOLSOS CON DOCUMENTACIÓN COMPLEMENTARIA'
                        "
                      />
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                      <v-switch
                        v-model="data.rs_refound_discharge"
                        class="pt-3"
                        color="success"
                        dense
                      />
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          label="Despues de (días)"
                          v-model="data.rs_refound_discharge_days"
                          min="1"
                          max="365"
                          type="number"
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12">
                        <small>
                          <i>
                            *Se comprueba diariamente con la fecha en que se
                            rechazo a las 00:00:01 hrs
                          </i>
                        </small>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  fab
                  x-small
                  color="info"
                  @click.prevent="storeUpdate"
                  :loading="ldg"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span v-text="'Editar'" />
            </v-tooltip>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import { URL_API, headersToken, rules, msgAlert, msgConfirm } from "@/control";
import FaqDlg from "@/components/FaqDlg.vue";
import ViewData from "@/components/ViewData.vue";
import DatePicker from "../../components/DatePicker.vue";

export default {
  components: {
    FaqDlg,
    ViewData,
    DatePicker,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      ldg: false,
      data: null,
      rules: rules(),
      provider_brands: [],
      provider_brands_ldg: true,
      providers: [],
      providers_ldg: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getData() {
      this.ldg = true;
      this.data = null;

      Axios.get(URL_API + "/config", headersToken(this.login.token)).then(
        (rsp) => {
          this.data = rsp.data.data;
          this.ldg = false;
        }
      );
    },
    storeUpdate() {
      if (this.$refs.data_form.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma la edición de la información?"))
          .then((rsp) => {
            if (rsp.isConfirmed) {
              this.ldg = true;

              let obj = Object.assign({}, this.data);
              obj._method = "PATCH";

              Axios.post(
                URL_API + "/config/1",
                obj,
                headersToken(this.login.token)
              ).then((rsp) => {
                this.$swal.fire(
                  msgAlert(
                    rsp.data.success ? "success" : "error",
                    rsp.data.message
                  )
                );

                if (rsp.data.success) {
                  this.$router.push({
                    name: "config",
                  });
                }

                this.ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((rsp) => {
      this.faqs = rsp.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(
      URL_API + "/providers/brands",
      headersToken(this.login.token)
    ).then((rsp) => {
      this.provider_brands = rsp.data.data;
      this.provider_brands.push({
        id: null,
        provider_brand: "**NINGUNO**",
      });
      this.provider_brands_ldg = false;
    });

    Axios.get(URL_API + "/providers", headersToken(this.login.token)).then(
      (rsp) => {
        this.providers = rsp.data.data;
        this.providers_ldg = false;
      }
    );

    this.getData();
  },
};
</script>